import React from "react";
import rating_css from "../css/rating.module.css";
const Rating = ({ rate = 0, size = 16 }) => {
  return (
    <div className={rating_css.rating_wrap}>
      {[0, 1, 2, 3, 4].map((item, index) => {
        return (
          <span key={index}>
            {rate >= item + 0.5 && rate < item + 1 ? (
              <i
                style={{
                  fontSize: size,
                }}
                className={`fa-regular fa-star-half-stroke ${rating_css.rating_star}`}
              ></i>
            ) : rate >= item + 1 ? (
              <i
                style={{
                  fontSize: size,
                }}
                className={`fa-solid fa-star ${rating_css.rating_star}`}
              ></i>
            ) : (
              <i
                style={{
                  fontSize: size,
                }}
                className={`fa-regular fa-star ${rating_css.rating_star}`}
              ></i>
            )}
          </span>
        );
      })}
    </div>
  );
};

export default Rating;
