import React, { Component } from "react";
import { connect } from "react-redux";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import Spinner from "../../component/Spinner";
import Onstatus from "../../component/Onstatus";
import Selfspinner from "../../component/Selfspinner";
import deatils_css from "../../css/udetails.module.css";
// dispatch actions
import { Userdetails } from "../../redux/actionCreator/Useractions";
import { Changeactive } from "../../redux/actionCreator/Useractions";
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    Userdetails_dis: (token, data, setState) =>
      dispatch(Userdetails(token, data, setState)),
    Changeactive_dis: (token, stk, setState, state, type) =>
      dispatch(Changeactive(token, stk, setState, state, type)),
  };
};
export class Details extends Component {
  constructor() {
    super();
    this.state = {
      udetails: null,
      loading: false,
      loadingac: false,
      loadingdl: false,
      loadingsu: false,
    };
    this.setState = this.setState.bind(this);
  }
  componentDidMount() {
    const url = new URL(window.location.href);
    const user = url.searchParams.get("user");
    if (user !== null && String(user).length > 0) {
      const data = {
        store_id: user,
      };
      this.props.Userdetails_dis(this.props.accessToken, data, this.setState);
    }
  }
  render() {
    const width = window.innerWidth;
    const udetails = this.state.udetails;
    const url = new URL(window.location.href);
    const store_key = url.searchParams.get("user");

    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Users" />
          <Contentwrap>
            <Header path="User Details" subpath="home/users/details" />
            <div className={deatils_css.u_details}>
              <div className={deatils_css.section}>
                <h4 className={deatils_css.section_label}>
                  Personal Information
                </h4>
                <div className={deatils_css.section_body}>
                  {udetails !== null ? (
                    <div className={deatils_css.personal_info_wrap}>
                      <div className={deatils_css.profile_pic_wrap}>
                        <img
                          className={deatils_css.profile_pic}
                          src={udetails.picture}
                          alt="user profile"
                        />
                        <div
                          style={{
                            backgroundColor:
                              Onstatus(udetails.last_active) === "Online"
                                ? "green"
                                : "grey",
                          }}
                          className={`${deatils_css.is_online} `}
                        ></div>
                      </div>
                      <div className={deatils_css.personal_info_overview}>
                        <h6 className={deatils_css.personal_overview_item}>
                          Username: <span>{String(udetails.username)}</span>
                        </h6>
                        <h6 className={deatils_css.personal_overview_item}>
                          Name: <span>{udetails.name}</span>
                        </h6>
                        <h6 className={deatils_css.personal_overview_item}>
                          Email: <span>{udetails.email}</span>
                        </h6>
                        <h6 className={deatils_css.personal_overview_item}>
                          Address: <span>{String(udetails.address)}</span>
                        </h6>
                        <h6 className={deatils_css.personal_overview_item}>
                          Listed: <span>{udetails.listed} item</span>
                        </h6>
                        <h6 className={deatils_css.personal_overview_item}>
                          Reviews:{" "}
                          <span>{`${udetails.rating.total}(${udetails.rating.average}*)`}</span>
                        </h6>
                        <h6 className={deatils_css.personal_overview_item}>
                          Followers: <span>{udetails.followers}</span>
                        </h6>
                        <h6 className={deatils_css.personal_overview_item}>
                          Following: <span>{udetails.following}</span>
                        </h6>
                        <h6 className={deatils_css.personal_overview_item}>
                          <span>{Onstatus(udetails.last_active)}</span>
                        </h6>
                        <div
                          onClick={() => {
                            if (
                              window.confirm(
                                `do you want ${
                                  udetails.is_active ? "active" : "deactive"
                                } this user?`
                              )
                            ) {
                              this.props.Changeactive_dis(
                                this.props.accessToken,
                                store_key,
                                this.setState,
                                this.state,
                                "status"
                              );
                            }
                          }}
                          className={`btn btn-${
                            udetails.is_active ? "success" : "danger"
                          } btn-lg ${deatils_css.deactive_btn}`}
                        >
                          {this.state.loadingac ? (
                            <div style={{ margin: "3px 0" }}>
                              <Selfspinner size={16} />
                            </div>
                          ) : udetails.is_active ? (
                            "active"
                          ) : (
                            "deactive"
                          )}
                        </div>
                        {/*delete action*/}
                        <div
                          onClick={() => {
                            if (
                              window.confirm(
                                `do you want to ${
                                  udetails.is_deleted ? "deleted" : "undo"
                                } user?`
                              )
                            ) {
                              {
                                this.props.Changeactive_dis(
                                  this.props.accessToken,
                                  store_key,
                                  this.setState,
                                  this.state,
                                  "delete"
                                );
                              }
                            }
                          }}
                          className={`btn btn-${
                            !udetails.is_deleted ? "success" : "danger"
                          } btn-lg ${deatils_css.deactive_btn}`}
                        >
                          {this.state.loadingdl ? (
                            <div style={{ margin: "3px 0" }}>
                              <Selfspinner size={16} />
                            </div>
                          ) : udetails.is_deleted ? (
                            "deleted"
                          ) : (
                            "no deleted"
                          )}
                        </div>
                        {/*suspend*/}
                        <div
                          onClick={() => {
                            if (
                              window.confirm(
                                `do you want to ${
                                  udetails.is_suspended ? "suspended" : "active"
                                } this user?`
                              )
                            ) {
                              this.props.Changeactive_dis(
                                this.props.accessToken,
                                store_key,
                                this.setState,
                                this.state,
                                "suspend"
                              );
                            }
                          }}
                          className={`btn btn-${
                            !udetails.is_suspended ? "success" : "danger"
                          } btn-lg ${deatils_css.deactive_btn}`}
                        >
                          {this.state.loadingsu ? (
                            <div style={{ margin: "3px 0" }}>
                              <Selfspinner size={16} />
                            </div>
                          ) : udetails.is_suspended ? (
                            "suspended"
                          ) : (
                            "no suspended"
                          )}
                        </div>
                        {/*permanent delete*/}
                        <div
                          onClick={() => {
                            if (
                              window.confirm(
                                `do you want to delete permanent this user? if you delete permanent you can't retrive data next time for this user!`
                              )
                            ) {
                              this.props.Changeactive_dis(
                                this.props.accessToken,
                                store_key,
                                this.setState,
                                this.state,
                                "permanentDelete"
                              );
                            }
                          }}
                          className={`btn btn-danger btn-lg ${deatils_css.deactive_btn}`}
                        >
                          {this.state.loadingsu ? (
                            <div style={{ margin: "3px 0" }}>
                              <Selfspinner size={16} />
                            </div>
                          ) : (
                            "Permanent Delete"
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* lists */}
              <div className={deatils_css.section}>
                <h4 className={deatils_css.section_label}>Lists</h4>
                <div className={deatils_css.section_body}>
                  {udetails !== null ? (
                    <div className={deatils_css.lists_wrap}>
                      {udetails.lists.map((item, index) => {
                        return (
                          <div
                            onClick={() =>
                              this.props.history.push(
                                `/lists/details?list=${item.list_key}`
                              )
                            }
                            key={index}
                            style={{ height: width / 4.5, minHeight: 80 }}
                            className={deatils_css.list_item_wrap}
                          >
                            <div className={deatils_css.list_item_img_wrap}>
                              <img
                                className={deatils_css.list_item_img}
                                src={item.picture}
                                alt="list"
                              />
                            </div>
                            <div className={deatils_css.list_item_body}>
                              <p className={deatils_css.list_item_title}>
                                {item.title}
                              </p>
                              <div className={deatils_css.list_item_overview}>
                                <p className={deatils_css.list_item_rented}>
                                  rented: <span>{item.rented}</span>
                                </p>
                                <p className={deatils_css.list_item_rating}>
                                  <span>{`reviews:${item.rating.total}(${item.rating.average}*)`}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
