import React, { Component } from "react";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import dateFormat from "dateformat";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import Spinner from "../../component/Spinner";
import Selfspinner from "../../component/Selfspinner";
import list_css from "../../css/list.module.css";

// disptch actions
import { Listsget } from "../../redux/actionCreator/Coreactions";
import { Listsgetload } from "../../redux/actionCreator/Coreactions";
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Listsget_dis: (token, setState, kw) =>
      dispatch(Listsget(token, setState, kw)),
    Listsgetload_dis: (token, setState, state, kw) =>
      dispatch(Listsgetload(token, setState, state, kw)),
  };
};
export class Lists extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      loadingmore: false,
      lists: [],
      search_kw: "",
    };
    this.setState = this.setState.bind(this);
  }
  componentDidMount() {
    this.props.Listsget_dis(this.props.accessToken, this.setState, "");
  }
  render() {
    const search = () => {
      this.props.Listsget_dis(
        this.props.accessToken,
        this.setState,
        this.state.search_kw
      );
    };
    const load_more = () => {
      this.props.Listsgetload_dis(
        this.props.accessToken,
        this.setState,
        this.state,
        this.state.search_kw
      );
    };

    const export_exal = async () => {
      const export_json = [];
      for (let list_item of this.state.lists) {
        const jsonobj = {
          "List Title": list_item.title,
          "List Category": list_item.category,
          "List Location": list_item.list_location,
          "List Colour": list_item.list_color,
          "List Size": list_item.list_size,
          "List Brand": list_item.list_brand,
          "3 Day Rental Price": list_item.price_3days,
          "5 Day Rental Price": list_item.price_5days,
          "10 Day Rental Price": list_item.price_10days,
          "Delivery Price": list_item.shipping_fees,
          "Pick-Up available": list_item.pickup_avail,
          "Retail Price": list_item.retail_price,
          "Number of Likes": list_item.num_likes,
          "Number of Times Rented": list_item.num_rental,
          "Review of Item": list_item.num_reviews,
        };
        export_json.push(jsonobj);
      }
      const file_type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(export_json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const exel_buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([exel_buffer], { type: file_type });
      FileSaver.saveAs(
        data,
        `ninthlife_lists_export${dateFormat(
          new Date(),
          "dd-mm-yyyy-HH:MM-TT"
        )}.xlsx`
      );
    };

    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Lists" />
          <Contentwrap>
            <Header path="Lists" subpath="home/lists" />
            <div className={list_css.lists}>
              <div className="section">
                <h4 className="section_label">Search</h4>
                <div className="section_body">
                  <div className={list_css.user_search_wrap}>
                    <input
                      type="text"
                      placeholder="search by list title keyword"
                      className={`form-control ${list_css.user_search_input}`}
                      value={this.state.search_kw}
                      onChange={(e) =>
                        this.setState({ search_kw: e.target.value })
                      }
                    />
                    <button
                      onClick={() => search()}
                      className={`btn btn-primary ${list_css.user_search_btn}`}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ position: "relative" }} className="section">
                <button
                  className={`${list_css.export_exal_btn} btn btn-success`}
                  onClick={() => export_exal()}
                >
                  export excel
                </button>
                <h4 className="section_label">Lists</h4>
                <div className="section_body">
                  <div className={list_css.lists_wrap}>
                    {this.state.lists.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{ minHeight: 300 }}
                          className={list_css.list_item_wrap}
                          onClick={() =>
                            this.props.history.push(
                              `/lists/details?list=${item.list_key}`
                            )
                          }
                        >
                          <div className={list_css.list_item_img_wrap}>
                            <img
                              className={list_css.list_item_img}
                              src={item.picture}
                              alt="list"
                            />
                          </div>
                          <div className={list_css.list_item_body}>
                            <p className={list_css.list_item_title}>
                              {item.title}
                            </p>
                            <div className={list_css.list_item_overview}>
                              <p className={list_css.list_item_rented}>
                                rented: <span>{item.rented}</span>
                              </p>
                              <p className={list_css.list_item_rating}>
                                <span>{`reviews:${item.rating.total}(${item.rating.average}*)`}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="section_body">
                  <div className={list_css.list_load_more_top}></div>
                  {this.state.loadingmore ? (
                    <div className="load_more_spinner_wrap">
                      <Selfspinner />
                    </div>
                  ) : (
                    <div
                      onClick={() => load_more()}
                      className={list_css.loadmore_btn}
                    >
                      Load more
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lists);
