import React, { Component } from "react";
import header_css from "../css/header.module.css";
import Logo from "../assets/clothstore.png";
import axios from "axios";
import { connect } from "react-redux";
// dispatch actions

const LoadingSMTPstatus = (setState) => {
  setState({
    loadingSMTPStatus: true,
  });
  return (dispatch) => {
    axios
      .get(`https://smtp-server.cdnssmtp-ninthemails.site/`)
      .then((response) => {
        try {
          if (response.data.status === true) {
            setState({
              smtp_server: true,
              loadingSMTPStatus: false,
            });
          } else {
            setState({
              smtp_server: false,
              loadingSMTPStatus: false,
            });
          }
        } catch {
          setState({
            smtp_server: false,
            loadingSMTPStatus: false,
          });
        }
      })
      .catch((err) => {
        setState({
          smtp_server: false,
          loadingSMTPStatus: false,
        });
      });
  };
};

const LoadingBKSTAstatus = (setState) => {
  setState({
    loadingBKSTAStatus: true,
  });
  return (dispatch) => {
    axios
      .get(`https://background-processors-24.ninthlife.eu/`)
      .then((response) => {
        try {
          if (response.data.status === true) {
            setState({
              background_processor: true,
              loadingBKSTAStatus: false,
            });
          } else {
            setState({
              background_processor: false,
              loadingBKSTAStatus: false,
            });
          }
        } catch {
          setState({
            background_processor: false,
            loadingBKSTAStatus: false,
          });
        }
      })
      .catch((err) => {
        setState({
          background_processor: false,
          loadingBKSTAStatus: false,
        });
      });
  };
};
const LoadingWebsocketstatus = (setState) => {
  setState({
    loadingWebsocket: true,
  });
  return (dispatch) => {
    axios
      .get(`https://websocket.ninthlife.eu/`)
      .then((response) => {
        try {
          if (response.data.status === true) {
            setState({
              websocketStatus: true,
              loadingWebsocket: false,
            });
          } else {
            setState({
              websocketStatus: false,
              loadingWebsocket: false,
            });
          }
        } catch {
          setState({
            websocketStatus: false,
            loadingWebsocket: false,
          });
        }
      })
      .catch((err) => {
        setState({
          websocketStatus: false,
          loadingWebsocket: false,
        });
      });
  };
};
const LoadingMobileAppstatus = (setState) => {
  setState({
    loadingMobileAppstatus: true,
  });
  return (dispatch) => {
    axios
      .get(`https://cdns-server.ninthlife.eu/core/server_status/`)
      .then((response) => {
        try {
          setState({
            mobileAppstatus: response.data.SERVER_STATUS,
            loadingMobileAppstatus: false,
          });
        } catch {
          setState({
            mobileAppstatus: "down",
            loadingMobileAppstatus: false,
          });
        }
      })
      .catch((err) => {
        setState({
          mobileAppstatus: "down",
          loadingMobileAppstatus: false,
        });
      });
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    LoadingSMTPstatus_dis: (setState) => dispatch(LoadingSMTPstatus(setState)),
    LoadingBKSTAstatus_dis: (setState) =>
      dispatch(LoadingBKSTAstatus(setState)),
    LoadingWebsocketstatus_dis: (setState) =>
      dispatch(LoadingWebsocketstatus(setState)),
    LoadingMobileAppstatus_dis: (setState) =>
      dispatch(LoadingMobileAppstatus(setState)),
  };
};

export class Header extends Component {
  constructor() {
    super();
    this.state = {
      smtp_server: false,
      loadingSMTPStatus: false,
      background_processor: false,
      loadingBKSTAStatus: false,
      websocketStatus: false,
      loadingWebsocket: false,
      mobileAppstatus: false,
      loadingMobileAppstatus: false,
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.props.LoadingSMTPstatus_dis(this.setState);
    this.props.LoadingBKSTAstatus_dis(this.setState);
    this.props.LoadingWebsocketstatus_dis(this.setState);
    this.props.LoadingMobileAppstatus_dis(this.setState);
  }
  render() {
    return (
      <div className={header_css.header_wrap}>
        <div className={header_css.header}>
          <div className={header_css.logo_wrap}>
            <img className={header_css.logo_img} src={Logo} alt="Logo" />
          </div>
          <div className={header_css.company_label_wrap}>
            <h3 className={header_css.company_name}>Ninth Life</h3>
            <h6 className={header_css.version}>Version: v0.1.0.4</h6>
          </div>
          <div className={header_css.path_wrap}>
            <h4 className={header_css.path_name}>{this.props.path}</h4>
            <h6 className={header_css.path_dir_name}>{this.props.subpath}</h6>
          </div>
          <div className={header_css.path_wrap}>
            <h5 className={header_css.server_status}>
              SMTP Server(
              <span
                style={{
                  color: this.state.loadingSMTPStatus
                    ? "black"
                    : this.state.smtp_server === true
                    ? "green"
                    : "red",
                }}
              >
                {this.state.loadingSMTPStatus
                  ? "loading..."
                  : this.state.smtp_server === true
                  ? "runing"
                  : "stoped"}
              </span>
              )
            </h5>
            <h5 className={header_css.server_status}>
              Background Processor(
              <span
                style={{
                  color: this.state.loadingBKSTAStatus
                    ? "black"
                    : this.state.background_processor === true
                    ? "green"
                    : "red",
                }}
              >
                {this.state.loadingBKSTAStatus
                  ? "loading..."
                  : this.state.background_processor === true
                  ? "runing"
                  : "stoped"}
              </span>
              )
            </h5>
          </div>
          {/* ws and status */}
          <div className={header_css.path_wrap}>
            <h5 className={header_css.server_status}>
              Websocket(
              <span
                style={{
                  color: this.state.loadingWebsocket
                    ? "black"
                    : this.state.websocketStatus === true
                    ? "green"
                    : "red",
                }}
              >
                {this.state.loadingWebsocket
                  ? "loading..."
                  : this.state.websocketStatus === true
                  ? "runing"
                  : "stoped"}
              </span>
              )
            </h5>
            <h5 className={header_css.server_status}>
              Mobile App(
              <span
                style={{
                  color: this.state.loadingMobileAppstatus
                    ? "black"
                    : this.state.mobileAppstatus === "activate"
                    ? "green"
                    : "red",
                }}
              >
                {this.state.loadingMobileAppstatus
                  ? "loading..."
                  : this.state.mobileAppstatus
                  ? this.state.mobileAppstatus
                  : "stoped"}
              </span>
              )
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(Header);
