import React, { Component } from "react";
import { connect } from "react-redux";
import login_css from "../../css/login.module.css";
import Spinner from "../../component/Spinner";

// dispatch actions
import { OTPverify } from "../../redux/actionCreator/Authactions";

const mapDispatchToProps = (dispatch) => {
  return {
    OTPverify_dis: (token, otp, setState, callback) =>
      dispatch(OTPverify(token, otp, setState, callback)),
  };
};
export class Otp extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp1Error: false,
      otp2Error: false,
      otp3Error: false,
      otp4Error: false,
      otp5Error: false,
      response: "",
    };
    this.setState = this.setState.bind(this);
  }
  render() {
    const onChange_otp = (e, index) => {
      this.setState({
        [`otp${index}`]:
          e.target.value.length > 0
            ? e.target.value[e.target.value.length - 1]
            : e.target.value,
      });
      if (index <= 4 && e.target.value.trim().length > 0) {
        this[`otp${index + 1}`].focus();
      } else if (e.target.value.trim().length < 1 && index > 0) {
        this[`otp${index - 1}`].focus();
      }
      // check_error(false);
    };
    const check_error = (will_set = true) => {
      let error = false;
      for (let i = 1; i <= 5; i++) {
        if (this.state[`otp${i}`].trim().length < 1) {
          error = true;
          if (will_set) {
            this.setState({
              [`otp${i}Error`]: true,
            });
          }
        } else {
          if (will_set) {
            this.setState({
              [`otp${i}Error`]: false,
            });
          }
        }
      }
      return error;
    };
    const OTPverify_callback = (type, data) => {
      if (type === true) {
        this.setState({
          response: "congratulations! admin signin success.",
        });
        this.gohome.push("/");
      } else {
        this.setState({
          response: "OTP doesn't match or expired!",
        });
      }
    };
    const verify = () => {
      if (check_error() === false) {
        const otp = `${
          this.state.otp1 +
          this.state.otp2 +
          this.state.otp3 +
          this.state.otp4 +
          this.state.otp5
        }`;
        this.props.OTPverify_dis(
          this.props.access_key,
          otp,
          this.setState,
          OTPverify_callback
        );
      }
    };
    return (
      <div className={login_css.login}>
        <div className={login_css.otp_wrap}>
          <h4 className={login_css.login_label}>2-step Verification</h4>
          {this.state.response.length > 0 ? (
            <p style={{ fontSize: 14, textAlign: "center", color: "red" }}>
              {this.state.response}
            </p>
          ) : null}
          <div className={login_css.otp_box}>
            <input
              ref={(otp1) => (this.otp1 = otp1)}
              maxLength={1}
              className={login_css.otp_item}
              style={{
                borderColor: this.state.otp1Error === true ? "red" : "grey",
              }}
              type="number"
              placeholder=""
              onChange={(e) => onChange_otp(e, 1)}
              value={this.state.otp1}
            />
            <input
              ref={(otp2) => (this.otp2 = otp2)}
              maxLength={1}
              className={login_css.otp_item}
              style={{
                borderColor: this.state.otp2Error === true ? "red" : "grey",
              }}
              type="number"
              placeholder=""
              onChange={(e) => onChange_otp(e, 2)}
              value={this.state.otp2}
            />
            <input
              ref={(otp3) => (this.otp3 = otp3)}
              maxLength={1}
              className={login_css.otp_item}
              style={{
                borderColor: this.state.otp3Error === true ? "red" : "grey",
              }}
              type="number"
              placeholder=""
              onChange={(e) => onChange_otp(e, 3)}
              value={this.state.otp3}
            />
            <input
              ref={(otp4) => (this.otp4 = otp4)}
              maxLength={1}
              className={login_css.otp_item}
              style={{
                borderColor: this.state.otp4Error === true ? "red" : "grey",
              }}
              type="number"
              placeholder=""
              onChange={(e) => onChange_otp(e, 4)}
              value={this.state.otp4}
            />
            <input
              ref={(otp5) => (this.otp5 = otp5)}
              maxLength={1}
              className={login_css.otp_item}
              style={{
                borderColor: this.state.otp5Error === true ? "red" : "grey",
              }}
              type="number"
              placeholder=""
              onChange={(e) => onChange_otp(e, 5)}
              value={this.state.otp5}
            />
          </div>
          <p
            onClick={() => verify()}
            className={[`${login_css.login_btn} ${login_css.otp_button}`]}
          >
            continue
          </p>
        </div>
        {this.state.loading ? <Spinner /> : null}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(Otp);
