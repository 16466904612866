import React, { Component } from "react";
import { connect } from "react-redux";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import Spinner from "../../component/Spinner";
import setting_css from "../../css/setting.module.css";

// dispatch actions
import { Linkssettingsget } from "../../redux/actionCreator/Coreactions";
import { Linkssettingspost } from "../../redux/actionCreator/Coreactions";

const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Linkssettingsget_dis: (token, setState) =>
      dispatch(Linkssettingsget(token, setState)),
    Linkssettingspost_dis: (token, data, setState) =>
      dispatch(Linkssettingspost(token, data, setState)),
  };
};
export class Links extends Component {
  constructor() {
    super();
    this.state = {
      site_url: "",
      playstore_link: "",
      appstore_link: "",
      express_refresh_url: "",
      express_return_url: "",
      how_does_work_link: "",
      help_link: "",
      exampleofgoodpic: "",
      user_share_link: "",
      site_urlError: false,
      playstore_linkError: false,
      appstore_linkError: false,
      express_refresh_urlError: false,
      express_return_urlError: false,
      how_does_work_linkError: false,
      exampleofgoodpicError: false,
      user_share_linkError: false,
      help_linkError: false,
      loading: false,
    };
    this.setState = this.setState.bind(this);
  }
  componentDidMount() {
    this.props.Linkssettingsget_dis(this.props.accessToken, this.setState);
  }
  render() {
    const check_error = () => {
      let error = false;
      const make_error = (name, value) => {
        this.setState({
          [name]: value,
        });
        if (value === true) {
          error = true;
        }
      };
      // check site url
      if (this.state.site_url.length > 0 && this.state.site_url !== null) {
        make_error("site_urlError", false);
      } else {
        make_error("site_urlError", true);
      }
      // check playstore url
      if (
        this.state.playstore_link.length > 0 &&
        this.state.playstore_link !== null
      ) {
        make_error("playstore_linkError", false);
      } else {
        make_error("playstore_linkError", true);
      }
      // check appstore url
      if (
        this.state.appstore_link.length > 0 &&
        this.state.appstore_link !== null
      ) {
        make_error("appstore_linkError", false);
      } else {
        make_error("appstore_linkError", true);
      }
      // check Express refresh url
      if (
        this.state.express_refresh_url.length > 0 &&
        this.state.express_refresh_url !== null
      ) {
        make_error("express_refresh_urlError", false);
      } else {
        make_error("express_refresh_urlError", true);
      }
      // check Express return url
      if (
        this.state.express_return_url.length > 0 &&
        this.state.express_return_url !== null
      ) {
        make_error("express_return_urlError", false);
      } else {
        make_error("express_return_urlError", true);
      }
      // check How does work link
      if (
        this.state.how_does_work_link.length > 0 &&
        this.state.how_does_work_link !== null
      ) {
        make_error("how_does_work_linkError", false);
      } else {
        make_error("how_does_work_linkError", true);
      }
      // check user share
      if (
        this.state.user_share_link.length > 0 &&
        this.state.user_share_link !== null
      ) {
        make_error("user_share_linkError", false);
      } else {
        make_error("user_share_linkError", true);
      }
      // check exampleof
      if (
        this.state.exampleofgoodpic.length > 0 &&
        this.state.exampleofgoodpic !== null
      ) {
        make_error("exampleofgoodpicError", false);
      } else {
        make_error("exampleofgoodpicError", true);
      }

      return error;
    };
    const save_setting = () => {
      if (!check_error()) {
        this.props.Linkssettingspost_dis(
          this.props.accessToken,
          this.state,
          this.setState
        );
      }
    };
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Setting" />
          <Contentwrap>
            <Header path="Rent" subpath="home/setting/rent" />
            <div className={setting_css.setting_wrap}>
              <div className="section">
                <h4 className="section_label">Rent Settings</h4>
                <div className="section_body">
                  <form>
                    <div className={setting_css.setting_item}>
                      <label>Client site url</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.site_urlError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://example.com/"
                        value={this.state.site_url}
                        onChange={(e) =>
                          this.setState({ site_url: e.target.value })
                        }
                      />
                    </div>
                    {/* playstore */}
                    <div className={setting_css.setting_item}>
                      <label>Playstore url</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.playstore_linkError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://play.google.com/store/apps/details?id=com.facebook"
                        value={this.state.playstore_link}
                        onChange={(e) =>
                          this.setState({ playstore_link: e.target.value })
                        }
                      />
                    </div>
                    {/* App store */}
                    <div className={setting_css.setting_item}>
                      <label>Appstore url</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.appstore_linkError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://apps.apple.com/us/app/facebook/id284882215"
                        value={this.state.appstore_link}
                        onChange={(e) =>
                          this.setState({ appstore_link: e.target.value })
                        }
                      />
                    </div>
                    {/* Express refresh url */}
                    <div className={setting_css.setting_item}>
                      <label>Express refresh url</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.express_refresh_urlError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://example.com/refresh"
                        value={this.state.express_refresh_url}
                        onChange={(e) =>
                          this.setState({ express_refresh_url: e.target.value })
                        }
                      />
                    </div>
                    {/* Express return url */}
                    <div className={setting_css.setting_item}>
                      <label>Express return url</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.express_return_urlError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://example.com/return"
                        value={this.state.express_return_url}
                        onChange={(e) =>
                          this.setState({ express_return_url: e.target.value })
                        }
                      />
                    </div>
                    {/* how_does_work_link */}
                    <div className={setting_css.setting_item}>
                      <label>How does work link</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.how_does_work_linkError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://example.com/blog/how_does_work_link"
                        value={this.state.how_does_work_link}
                        onChange={(e) =>
                          this.setState({ how_does_work_link: e.target.value })
                        }
                      />
                    </div>
                    {/* help_link */}
                    <div className={setting_css.setting_item}>
                      <label>Help link</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.help_linkError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://example.com/help"
                        value={this.state.help_link}
                        onChange={(e) =>
                          this.setState({ help_link: e.target.value })
                        }
                      />
                    </div>

                    {/* user Share */}
                    <div className={setting_css.setting_item}>
                      <label>Example of good photos and Videos</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.exampleofgoodpicError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://example.com/help"
                        value={this.state.exampleofgoodpic}
                        onChange={(e) =>
                          this.setState({ exampleofgoodpic: e.target.value })
                        }
                      />
                    </div>

                    {/* example photos and videos */}
                    <div className={setting_css.setting_item}>
                      <label>User share base url</label>
                      <input
                        className="form-control"
                        style={{
                          borderColor: this.state.user_share_linkError
                            ? "red"
                            : "transparent",
                        }}
                        type="text"
                        placeholder="ex: https://example.com/help"
                        value={this.state.user_share_link}
                        onChange={(e) =>
                          this.setState({ user_share_link: e.target.value })
                        }
                      />
                    </div>
                    <div
                      onClick={() => save_setting()}
                      className={`btn btn-sm btn-success ${setting_css.save_setting_btn}`}
                    >
                      save setting
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Links);
