import React, { Component } from "react";
import { connect } from "react-redux";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import Spinner from "../../component/Spinner";
import home_css from "../../css/home.module.css";
import { home_sections } from "../../core";

// dispatch actions
import { Homeoverviewget } from "../../redux/actionCreator/Coreactions";

const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Homeoverviewget_dis: (token, setState) =>
      dispatch(Homeoverviewget(token, setState)),
  };
};
export class Home extends Component {
  constructor() {
    super();
    this.state = {
      overview: {
        Today: {
          Hired: "...",
          Pending: "...",
          Checkout: "...",
          Payment: "...",
        },
        Last7: {
          Hired: "...",
          Pending: "...",
          Checkout: "...",
          Payment: "...",
        },
        Last30: {
          Hired: "...",
          Pending: "...",
          Checkout: "...",
          Payment: "...",
        },
        Last60: {
          Hired: "...",
          Pending: "...",
          Checkout: "...",
          Payment: "...",
        },
      },
      loading: false,
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.props.Homeoverviewget_dis(this.props.accessToken, this.setState);
  }
  render() {
    const overview_label_return = (name) => {
      if (name === "Today") {
        return "Today";
      } else if (name === "Last7") {
        return "Last 7 days";
      } else if (name === "Last30") {
        return "Last 30 days";
      } else if (name === "Last60") {
        return "Last 60 days";
      } else {
        return "None";
      }
    };
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Home" />
          <Contentwrap>
            <Header path="Home" subpath="home/dashboard" />
            <div className={home_css.home_wrap}>
              {home_sections.map((item, index) => {
                return (
                  <div key={index} className={home_css.home_section}>
                    <h4 className={home_css.section_label}>
                      {overview_label_return(item.name)}
                    </h4>
                    <div className={home_css.section_body}>
                      {item.subsection.map((secitem, secitindex) => {
                        return (
                          <div
                            key={secitindex}
                            className={home_css.section_item}
                          >
                            <h5 className={home_css.section_item_label}>
                              {secitem}
                            </h5>
                            <div className={home_css.sec_item_body}>
                              <h3 className={home_css.overview_count_val}>
                                {secitem === "Payment" ? "€" : null}
                                {this.state.overview[item.name][secitem]}
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
