import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Sidebar from "../../component/Sidebar";
import Header from "../../component/Header";
import Spinner from "../../component/Spinner";
import Selfspinner from "../../component/Selfspinner";
import sliders_css from "../../css/sliders.module.css";

// dispatch actions
import { Slidersget } from "../../redux/actionCreator/Coreactions";
import { Sliderput } from "../../redux/actionCreator/Coreactions";
import { Sliderdelete } from "../../redux/actionCreator/Coreactions";
import { Sliderdpost } from "../../redux/actionCreator/Coreactions";
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    Slidersget_dis: (token, setState) => dispatch(Slidersget(token, setState)),
    Sliderput_dis: (token, data, callabck) => dispatch(Sliderput(token, data, callabck)),
    Sliderdelete_dis: (token, data, callabck) => dispatch(Sliderdelete(token, data, callabck)),
    Sliderdpost_dis: (token, data, setState, state) => dispatch(Sliderdpost(token, data, setState, state))
  }
}
export class Sliders extends Component {
  constructor() {
    super();
    this.state = {
      sliders: [],
      loading: false,
      loadingsv: false,
      loadingdl: false,
      loadingpo: false,
      newtitle: "",
      newlinktitle: "",
      newlink: "",
      newpicture: null,
      newtitleError: false,
      newlinkError: false,
      newpictureError: false,
      newlinktitleError: false
    };
    this.setState = this.setState.bind(this)
  }
  componentDidMount() {
    this.props.Slidersget_dis(this.props.accessToken, this.setState)
  }
  render() {
    const pick_new_image = (event) => {
      this.setState({
        newpicture: event.target.files[0],
      });
    };
    const check_input_error = () => {
      let error = false
      const make_error = (name, value) => {
        this.setState({
          [name]: value
        })
        if (value === true) {
          error = true
        }
      }
      // check picture
      if (this.state.newpicture !== null) {
        make_error("newpictureError", false)
      } else {
        make_error("newpictureError", true)
      }
      // check title
      if (this.state.newtitle.length > 0) {
        make_error("newtitleError", false)
      } else {
        make_error("newtitleError", true)
      }
      // check link title 
      if (this.state.newlinktitle.length > 0) {
        make_error("newlinkError", false)
      } else {
        make_error("newlinkError", true)
      }
      // check link
      if (this.state.newlink.length > 0) {
        // var link = this.state.newlink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        make_error("newlinktitleError", false)
      } else {
        make_error("newlinktitleError", true)
      }
      return error
    }
    const create_slide = () => {
      if (!check_input_error()) {
        let formData = new FormData();
        formData.append('picture', this.state.newpicture);
        formData.append('title', this.state.newtitle);
        formData.append('link_title', this.state.newlinktitle);
        formData.append('link', this.state.newlink);
        this.props.Sliderdpost_dis(this.props.accessToken, formData, this.setState, this.state)
      }
    }
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Sliders" />
          <Contentwrap>
            <Header path="Sliders" subpath="home/sliders" />
            <div className={sliders_css.sliders}>
              <div className="section">
                <h4 className="section_label">Create New</h4>
                <div className="section_body">
                  <div className={sliders_css.sliders_input_wrap}>
                    <div className={sliders_css.slider_input_img_wrap}>
                      <label
                        style={{
                          borderColor: this.state.newpictureError ? 'red' : this.state.newpicture === null
                            ? "#fff"
                            : "transparent",
                        }}
                        className={sliders_css.slider_img_input_label}
                      >
                        {this.state.newpicture !== null ? (
                          <img
                            className={sliders_css.new_picture}
                            src={URL.createObjectURL(this.state.newpicture)}
                            alt=""
                          />
                        ) : (
                          "+"
                        )}

                        <input
                          className={sliders_css.slider_img_input}
                          type="file"
                          onChange={(e) => pick_new_image(e)}
                          accept="image/*"
                        />
                      </label>
                    </div>
                    <div className={sliders_css.slider_input_right}>
                      <form >
                        <div className={sliders_css.slider_input_item_wrap}>
                          <label className={sliders_css.slider_input_label}>
                            title
                          </label>
                          <input
                            className={`${sliders_css.slider_input_title} form-control`}
                            type="text"
                            placeholder="ex: how to use this app"
                            value={this.state.newtitle}
                            onChange={e => this.setState({ newtitle: e.target.value })}
                            style={{
                              borderColor: this.state.newtitleError ? 'red' : "transparent"
                            }}
                          />
                        </div>
                        {/* link input */}
                        <div className={sliders_css.slider_input_item_wrap}>
                          <label
                            className={`${sliders_css.slider_input_label} mt-1`}
                          >
                            link title
                          </label>
                          <input
                            className={`${sliders_css.slider_input_title} form-control`}
                            type="text"
                            placeholder="ex: how can we help you?"
                            value={this.state.newlinktitle}
                            onChange={e => this.setState({ newlinktitle: e.target.value })}
                            style={{
                              borderColor: this.state.newlinktitleError ? 'red' : "transparent"
                            }}
                          />
                        </div>
                        {/* link input */}
                        <div className={sliders_css.slider_input_item_wrap}>
                          <label
                            className={`${sliders_css.slider_input_label} mt-1`}
                          >
                            visit link
                          </label>
                          <input
                            className={`${sliders_css.slider_input_title} form-control`}
                            type="text"
                            placeholder="ex: https://example.com/blog/1233"
                            value={this.state.newlink}
                            onChange={e => this.setState({ newlink: e.target.value })}
                            style={{
                              borderColor: this.state.newlinkError ? 'red' : "transparent"
                            }}
                          />
                        </div>
                        <div onClick={() => {
                          if (!this.state.loadingpo) {
                            create_slide()
                          }
                        }} className="btn btn-sm btn-success mt-2">
                          {this.state.loadingpo ? <Selfspinner size={22} /> : "Create Slider"}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* section list */}
              <div className="section">
                <h4 className="section_label">sliders</h4>
                <div className="section_body">
                  <div className={sliders_css.sliders_list_wrap}>
                    {this.state.sliders.map((item, index) => {
                      return (
                        <Slideritem
                          key={index}
                          item={item}
                          setState={this.setState}
                          state={this.state}
                          sliders_css={sliders_css}
                          eddis={this.props.Sliderput_dis}
                          props={this.props}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

const Slideritem = ({ item, sliders_css, setState, state, eddis, props }) => {
  const [edit, setedit] = useState(false);
  const [loadingsv, setloadingsv] = useState(false)
  const [loadingdl, setloadingdl] = useState(false)
  const [picture, setpicture] = useState(null)
  const callbacksv = (type, data) => {
    setloadingsv(false)
    if (type === true) {
      const sliders = state.sliders
      let updated_sl = []
      for (let sli of sliders) {
        if (sli.id !== data.id) {
          updated_sl.push(sli)
        } else {
          updated_sl.push(data)
        }
      }
      setState({
        sliders: updated_sl
      })
    }
  }
  const save = () => {
    let formData = new FormData();
    formData.append('slide_id', item.id);
    formData.append('title', item.title);
    formData.append('link_title', item.link_title);
    formData.append('link', item.link);
    formData.append('is_imgchange', item.is_imgchange)
    formData.append('picture', picture);
    setloadingsv(true)
    eddis(props.accessToken, formData, callbacksv)
  }
  const callbackdl = (type, data) => {
    setloadingdl(false)
    if (type === true) {
      const sliders = state.sliders
      let updated_sl = []
      for (let sli of sliders) {
        if (sli.id !== data.id) {
          updated_sl.push(sli)
        }
      }
      setState({
        sliders: updated_sl
      })
    }
  }
  const deleteitem = () => {
    const data = {
      slide_id: item.id
    }
    setloadingdl(true)
    props.Sliderdelete_dis(props.accessToken, data, callbackdl)
  }
  const change_val = (name, val) => {
    const sliders = state.sliders
    let updated_sl = []
    for (let sli of sliders) {
      if (sli.id !== item.id) {
        updated_sl.push(sli)
      } else {
        sli[name] = val
        updated_sl.push(sli)
      }
    }
    setState({
      sliders: updated_sl
    })
  }
  return (
    <div className={sliders_css.slider_item_wrap}>
      <div className={sliders_css.slider_img_wrap}>
        <img
          className={sliders_css.slider_it_img}
          src={item.picture}
          alt="slider pic"
        />
        {edit ? (
          <label
            className={`btn btn-sm btn-primary ${sliders_css.slider_edit_image_btn}`}
          >
            <p>change</p>
            <input
              className={sliders_css.slider_img_input}
              type="file"
              onChange={(e) => {
                setpicture(e.target.files[0]);
                const sliders = state.sliders
                let updated_sl = []
                for (let sli of sliders) {
                  if (sli.id !== item.id) {
                    updated_sl.push(sli)
                  } else {
                    sli.is_imgchange = true
                    sli.picture = URL.createObjectURL(e.target.files[0])
                    updated_sl.push(sli)
                  }
                }
                setState({
                  sliders: updated_sl
                })
              }}
              accept="image/*"
            />
          </label>
        ) : null}
      </div>
      <div className={sliders_css.edit_item_wrap}>
        <div
          onClick={() => {
            setedit(!edit)
            if (edit && !loadingsv) {
              save()
            }
          }}
          className={`btn btn-sm btn-success ${sliders_css.slider_edit_btn}`}
        >
          {loadingsv ? <Selfspinner size={22} /> : edit ? "save" : "edit"}
        </div>
        <div
          onClick={() => {
            if (!loadingdl) {
              deleteitem()
            }
          }}
          className={`btn btn-sm btn-danger ${sliders_css.slider_edit_btn}`}
        >
          {loadingdl ? <Selfspinner size={22} /> : "delete"}
        </div>
      </div>
      <div className={sliders_css.slider_item_bottom}>
        {edit ? (
          <input
            onChange={e => change_val('title', e.target.value)}
            value={item.title}
            className="form-control"
            type="text"
          />
        ) : (
          <p className={sliders_css.alider_item_title}>{item.title}</p>
        )}
        {edit ? (
          <input
            onChange={e => change_val('link_title', e.target.value)}
            value={item.link_title}
            className="form-control mt-1"
            type="text"
          />
        ) : (
          <p className={sliders_css.link}>{item.link_title}</p>
        )}
        {edit ? (
          <input
            onChange={e => change_val('link', e.target.value)}
            value={item.link}
            className="form-control mt-1"
            type="text"
          />
        ) : (
          <p className={sliders_css.link}>{item.link}</p>
        )}
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Sliders);
