// import * as actionTypes from "../actionTypes";
// import { header } from "../Initialstate";
import { Commondata } from "../Initialstate";
import axios from "axios";
const baseUrl = Commondata.url;
// const version = Commondata.version;
// const access_key = Commondata.access_key;
const header = Commondata.header;
// actions

// home
export const Homeoverviewget = (token, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl}/home/super/homeoverview`, header(token, false, true))
      .then((response) => {
        setState({
          overview: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};
export const Slidersget = (token, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl}/home/slider/`, header(token, false, true))
      .then((response) => {
        setState({
          sliders: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

export const Sliderput = (token, data, callback) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl}/home/slider/`, data, header(token, false, true))
      .then((response) => {
        callback(true, response.data);
      })
      .catch((err) => {
        callback(false, "something wrong");
      });
  };
};
export const Sliderdelete = (token, data, callback) => {
  return (dispatch) => {
    axios
      .delete(`${baseUrl}/home/slider/`, {
        ...header(token, false, true),
        data: data,
      })
      .then((response) => {
        callback(true, response.data);
      })
      .catch((err) => {
        callback(false, "something wrong");
      });
  };
};
export const Sliderdpost = (token, data, setState, state) => {
  setState({
    loadingpo: true,
  });
  return (dispatch) => {
    axios
      .post(`${baseUrl}/home/slider/`, data, header(token, false, true))
      .then((response) => {
        setState({
          sliders: state.sliders.concat(response.data),
          loadingpo: false,
          newtitle: "",
          newlinktitle: "",
          newlink: "",
          newpicture: null,
          newtitleError: false,
          newlinkError: false,
          newpictureError: false,
          newlinktitleError: false,
        });
      })
      .catch((err) => {
        setState({
          loadingpo: false,
        });
      });
  };
};

export const Listsgetload = (token, setState, state, kw) => {
  setState({
    loadingmore: true,
  });
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/list/super/lists_get/?search_kw=${kw}&loaded=${state.lists.length}`,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          lists: state.lists.concat(response.data),
          loadingmore: false,
        });
      })
      .catch((err) => {
        setState({
          loadingmore: false,
        });
      });
  };
};
export const Listsget = (token, setState, kw) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/list/super/lists_get/?search_kw=${kw}&loaded=0`,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          lists: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

// list details actions
export const Listdetailsget = (token, setState, ky) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/list/super/list_details/?list_key=${ky}`,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          details: response.data,
          ...response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

// chats actions
export const Chatsget = (token, setState, kw) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/chat/super/chats_get?search_kw=${kw}&loaded=0`,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          chats: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

// settings
export const Rentsettingsget = (token, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl}/setting/super/rent_setting/`, header(token, false, true))
      .then((response) => {
        setState({
          ...response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

export const Rentsettingspost = (token, data, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .post(
        `${baseUrl}/setting/super/rent_setting/`,
        data,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          ...response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

// settings=>api
export const Apisettingsget = (token, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl}/setting/super/api_setting/`, header(token, false, true))
      .then((response) => {
        setState({
          ...response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

export const Apisettingspost = (token, data, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .post(
        `${baseUrl}/setting/super/api_setting/`,
        data,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          ...response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

// setting=>link
export const Linkssettingsget = (token, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl}/setting/super/link_setting/`, header(token, false, true))
      .then((response) => {
        setState({
          ...response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};
export const Linkssettingspost = (token, data, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .post(
        `${baseUrl}/setting/super/link_setting/`,
        data,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          ...response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

export const Changelist = (token, data, setState, callback) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .put(
        `${baseUrl}/list/super/list_details/`,
        data,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          loading: false,
        });
        if (response.data.category) {
          callback(true, response.data);
        } else {
        }
      })
      .catch((err) => {
        setState({
          loading: false,
        });
        callback(false, "");
      });
  };
};

export const DeleteList = (token, list_key, setState, state) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .delete(
        `${baseUrl}/list/super/list_details?list_key=${list_key}`,
        header(token)
      )
      .then((response) => {
        setState({
          loading: false,
        });
        if (response.data.status === "deleted") {
          setState({
            details: { ...state.details, status: response.data.status },
          });
          alert("List deleted successfully");
        } else {
          alert("List deleted failed");
        }
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

export const Reportsget = (token, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl}/info/super/reports/?loaded=0`, header(token))
      .then((response) => {
        setState({
          reports: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

export const Reportsgetloadmore = (token, setState, state) => {
  setState({
    loadingmore: true,
  });
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/info/super/reports/?loaded=${state.reports.length}`,
        header(token)
      )
      .then((response) => {
        setState({
          reports: [...state.reports, ...response.data],
          loadingmore: false,
        });
      })
      .catch((err) => {
        setState({
          loadingmore: false,
        });
      });
  };
};

export const Report_update = (token, data, setState, state) => {
  return (dispatch) => {
    axios
      .patch(`${baseUrl}/info/super/reports/`, data, header(token))
      .then((response) => {
        let reports = state.reports;
        let updated_rep = [];
        for (let rep of reports) {
          if (rep.id === response.data.id) {
            updated_rep.push(response.data);
          } else {
            updated_rep.push(rep);
          }
        }
        setState({
          reports: updated_rep,
          loading_sol: false,
        });
      })
      .catch(function (err) {
        setState({
          loading_sol: false,
        });
      });
  };
};

// report unseens
export const Reportunseenget = (token, setState) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/info/super/report_unseen/`, header(token))
      .then((response) => {
        setState({
          rports: response.data,
        });
      })
      .catch((err) => {});
  };
};

// coupon actions
export const Couponsget = (token, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl}/hire/coupongpd/`, header(token))
      .then((response) => {
        setState({
          coupons: response.data,
          loading: false,
        });
      })
      .catch(function (error) {
        setState({
          loading: false,
        });
      });
  };
};

export const Couponcreate = (token, data, setState, state) => {
  setState({
    loadingcre: true,
  });
  return (dispatch) => {
    axios
      .post(`${baseUrl}/hire/coupongpd/`, data, header(token))
      .then((response) => {
        setState({
          coupons: [...state.coupons, response.data],
          loadingcre: false,
        });
      })
      .catch(function (error) {
        try {
          alert(error.response.data.error_type);
        } catch {
          alert("Coupon create failed! try again");
        }
        setState({
          loadingcre: false,
        });
      });
  };
};

// coupon update
export const Couponupdate = (token, data, callback) => {
  return (dispatch) => {
    axios
      .patch(`${baseUrl}/hire/coupongpd/`, data, header(token))
      .then((response) => {
        callback(true, response.data);
      })
      .catch(function (error) {
        try {
          if (typeof error.response.data === "object") {
            callback(false, error.response.data.error_type);
          } else {
            callback(false, "sorry! coupon update failed!");
          }
        } catch {
          callback(false, "sorry! coupon update failed!");
        }
      });
  };
};

// sent notification for all
export const Allusersnotification = (token, data, setState) => {
  return (dispatch) => {
    setState({
      loadingnt: true,
    });
    axios
      .post(
        `${baseUrl}/notification/super/allusersnotify/`,
        data,
        header(token)
      )
      .then((response) => {
        setState({
          loadingnt: false,
          title: "",
          picture: null,
          message: "",
        });
        if (response.data.status === true) {
          alert("Notification sent success to All active users");
        } else {
          alert("sorry! notification sent failed!");
        }
      })
      .catch(function (error) {
        setState({
          loadingnt: false,
        });
        try {
          if (typeof error.response.data === "object") {
            alert(error.response.data.error_type);
          } else {
            alert("sorry! notification sent failed!");
          }
        } catch {
          alert("sorry! notification sent failed!");
        }
      });
  };
};

export const Rentalsget = (
  token,
  ld = 0,
  setState,
  is_loadnore = false,
  state
) => {
  if (is_loadnore) {
    setState({
      loadingld: true,
    });
  } else {
    setState({
      loading: true,
    });
  }

  return (dispatch) => {
    axios
      .get(`${baseUrl}/hire/super/rentals?loaded=${ld}`, header(token))
      .then((response) => {
        if (is_loadnore) {
          setState({
            rentals: state.rentals.concat(response.data),
            loadingld: false,
          });
        } else {
          setState({
            rentals: response.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (is_loadnore) {
          setState({
            loadingld: false,
          });
        } else {
          setState({
            loading: false,
          });
        }
      });
  };
};
