// import * as actionTypes from "../actionTypes";
// import { header } from "../Initialstate";
import { Commondata } from "../Initialstate";
import axios from "axios";
const baseUrl = Commondata.url;
// const version = Commondata.version;
// const access_key = Commondata.access_key;
const header = Commondata.header;
// actions
export const Usersget = (token, setState, kw, loading = true) => {
  setState({
    loading: loading,
  });
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/user/super/users_get?search_kw=${kw}`,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          users: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

export const Userloadmore = (token, setState, state, kw) => {
  setState({
    loadingld: true,
  });
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/user/super/users_get?search_kw=${kw}&loaded=${state.users.length}`,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          users: state.users.concat(response.data),
          loadingld: false,
        });
      })
      .catch((err) => {
        setState({
          loadingld: false,
        });
      });
  };
};
export const Userdetails = (token, data, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .post(
        `${baseUrl}/user/super/user_details/`,
        data,
        header(token, false, true)
      )
      .then((response) => {
        setState({
          udetails: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
        });
      });
  };
};

export const Changeactive = (token, stk, setState, state, type) => {
  if (type === "status") {
    setState({
      loadingac: true,
    });
  } else if (type === "delete") {
    setState({
      loadingdl: true,
    });
  } else {
    setState({
      loadingsu: true,
    });
  }
  return (dispatch) => {
    axios
      .get(
        `${baseUrl}/user/super/user_details/?store_key=${stk}&type=${type}`,
        header(token, false, true)
      )
      .then((response) => {
        if (response.data.is_permanent_delete === true) {
          setState({
            loadingac: false,
          });
          window.location.replace("/users/");
        } else {
          setState({
            udetails: { ...state.udetails, ...response.data },
          });
          if (type === "status") {
            setState({
              loadingac: false,
            });
          } else if (type === "delete") {
            setState({
              loadingdl: false,
            });
          } else {
            setState({
              loadingsu: false,
            });
          }
        }
      })
      .catch((err) => {
        if (type === "status") {
          setState({
            loadingac: false,
          });
        } else if (type === "delete") {
          setState({
            loadingdl: false,
          });
        } else {
          setState({
            loadingsu: false,
          });
        }
      });
  };
};
