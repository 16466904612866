import React, { Component } from "react";
import { connect } from "react-redux";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import details_css from "../../css/ldetails.module.css";
import Slider from "../../component/Slider";
import Spinner from "../../component/Spinner";
import Rating from "../../component/Rating";
// temp assets
import Review from "../../component/Review";

// dispatch action
import { Listdetailsget } from "../../redux/actionCreator/Coreactions";
import { Changelist } from "../../redux/actionCreator/Coreactions";
import { DeleteList } from "../../redux/actionCreator/Coreactions";

const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Listdetailsget_dis: (token, setState, ky) =>
      dispatch(Listdetailsget(token, setState, ky)),
    Changelist_dis: (token, data, setState, callback) =>
      dispatch(Changelist(token, data, setState, callback)),
    DeleteList_dis: (token, list_key, setState, state) =>
      dispatch(DeleteList(token, list_key, setState, state)),
  };
};
export class Listdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      details: null,
      title: "",
      titleError: false,
      description: "",
      descriptionError: false,
      category: "",
      categoryError: false,
      color: "black",
      colorError: false,
      size: "12",
      sizeError: false,
      brand: "",
      brandError: false,
      pricing: {
        day3: "",
        day5: "",
        day10: "",
      },
      pricing3Error: false,
      pricing5Error: false,
      pricing10Error: false,
      shipping: "",
      shippingError: false,
      retail: "",
      retailError: false,
    };
    this.setState = this.setState.bind(this);
  }
  componentDidMount() {
    const url = new URL(window.location.href);
    const list = url.searchParams.get("list");
    if (list !== null && String(list).length > 0) {
      this.props.Listdetailsget_dis(
        this.props.accessToken,
        this.setState,
        list
      );
    }
  }
  render() {
    const details = this.state.details;

    const cis_selected = (category) => {
      if (this.state.category === category.trim()) {
        return true;
      } else {
        return false;
      }
    };
    const Color_selected = (color) => {
      if (this.state.color === color.trim()) {
        return true;
      } else {
        return false;
      }
    };
    const Size_selected = (size) => {
      if (this.state.size === size.trim()) {
        return true;
      } else {
        return false;
      }
    };
    const list_change_callback = (type, data) => {
      if (type === true) {
        this.setState({
          ...data,
        });
        alert("successfully saved category");
      } else {
        alert("Sorry!Category Change Failed!");
      }
    };

    const save_changes = async () => {
      const error_status = await check_Error();
      if (error_status === false) {
        const data = {
          list_key: this.state.details.list_key,
          title: this.state.title,
          description: this.state.description,
          category: this.state.category,
          color: this.state.color,
          color_hex: color_codes.find((obj) => obj.value === this.state.color)
            .hex,
          size: this.state.size,
          brand: this.state.brand,
          pricing: this.state.pricing,
          shipping: this.state.shipping,
          retail: this.state.retail,
        };
        this.props.Changelist_dis(
          this.props.accessToken,
          data,
          this.setState,
          list_change_callback
        );
      } else {
        alert("Error:\n fill-up all field with valid data!");
      }
    };

    const delete_list = (list_key) => {
      if (window.confirm("Are you sure you want to delete this list?")) {
        this.props.DeleteList_dis(
          this.props.accessToken,
          list_key,
          this.setState,
          this.state
        );
      } else {
      }
    };
    const color_codes = [
      { hex: "#000000", value: "Black" },
      { hex: "#55A860", value: "Green" },
      { hex: "#9A7352", value: "Brown" },
      { hex: "#ED7A9E", value: "Pink" },
      { hex: "#FFC000", value: "Yellow" },
      { hex: "#F18036", value: "Orange" },
      { hex: "#764C82", value: "Purple" },
      { hex: "#DC413B", value: "Red" },
      { hex: "#FFFFFF", value: "White" },
      { hex: "#BCBCBC", value: "Grey" },
      { hex: "#0070C0", value: "Blue" },
      { hex: "#C3A98B", value: "Light Brown" },
      { hex: "#E5E5E5", value: "Multi" },
      { hex: "#EDB763", value: "Gold" },
    ];

    const sizes = [
      "4",
      "6",
      "8",
      "10",
      "12",
      "14",
      "16",
      "18",
      "20",
      "22",
      "24",
    ];

    // error check
    const check_Error = () => {
      let error = false;
      // make error
      const make_error = (field, value) => {
        this.setState({
          [field]: value,
        });
        if (value === true) {
          error = true;
        }
      };
      // check title error
      if (
        50 < this.state.title.trim().length ||
        this.state.title.trim().length < 5
      ) {
        make_error("titleError", true);
      } else {
        make_error("titleError", false);
      }
      // check description
      if (
        1000 < this.state.description.trim().length ||
        this.state.description.trim().length < 5
      ) {
        make_error("descriptionError", true);
      } else {
        make_error("descriptionError", false);
      }
      // check category
      if (this.state.category.trim().length < 1) {
        make_error("categoryError", true);
      } else {
        make_error("categoryError", false);
      }
      // color error
      if (this.state.color.trim().length < 1) {
        make_error("colorError", true);
      } else {
        make_error("colorError", false);
      }
      // check size
      if (this.state.size.trim().length < 1) {
        make_error("sizeError", true);
      } else {
        make_error("sizeError", false);
      }
      // check brand
      if (this.state.brand.trim().length < 4) {
        make_error("brandError", true);
      } else {
        make_error("brandError", false);
      }
      // check pricing day 03
      if (
        isNaN(Number(this.state.pricing.day3)) ||
        Number(this.state.pricing.day3) < 20
      ) {
        make_error("pricing3Error", true);
      } else {
        make_error("pricing3Error", false);
      }
      // check pricing day 05
      if (
        isNaN(Number(this.state.pricing.day5)) ||
        Number(this.state.pricing.day5) < 20
      ) {
        make_error("pricing5Error", true);
      } else {
        make_error("pricing5Error", false);
      }
      // check pricing day 10
      if (
        isNaN(Number(this.state.pricing.day10)) ||
        Number(this.state.pricing.day10) < 20
      ) {
        make_error("pricing10Error", true);
      } else {
        make_error("pricing10Error", false);
      }
      //shipping fees
      if (isNaN(Number(this.state.shipping))) {
        make_error("shippingError", true);
      } else {
        make_error("shippingError", false);
      }
      // retail error
      if (isNaN(Number(this.state.retail)) || Number(this.state.retail) < 100) {
        make_error("retailError", true);
      } else {
        make_error("retailError", false);
      }
      return error;
    };

    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Lists" />
          <Contentwrap>
            <Header path="List Details" subpath="home/lists/details" />
            <div className={details_css.details_wrap}>
              <div className="section">
                <h4 className="section_label">List details</h4>
                <div className="section_body">
                  {details !== null ? (
                    <div className={details_css.list_details_body}>
                      <div className={details_css.details_header_wrap}>
                        <div className={details_css.list_profile_wrap}>
                          <img
                            className={details_css.list_profile_img}
                            src={details.user.picture}
                            alt="lender "
                          />
                          <div className={details_css.list_profile_right}>
                            <h5 className={details_css.list_profile_name}>
                              {details.user.name}
                            </h5>
                            <p className={details_css.list_profile_address}>
                              {details.user.adress}
                            </p>
                          </div>
                        </div>
                        <div className={details_css.list_reacts_reviews}>
                          <div className={details_css.list_reacts_wrap}>
                            <i
                              style={{ color: "red" }}
                              className="fa-solid fa-heart"
                            ></i>
                            <p className={details_css.heart_count}>
                              {details.reacts}
                            </p>
                          </div>
                          <div className={details_css.list_rating_wrap}>
                            <Rating rate={details.rating.average} />
                            <p>{` (${details.rating.average})`}</p>
                          </div>
                        </div>
                      </div>
                      <div className={details_css.list_slider_wrap}>
                        <Slider data={details.pictures} />
                      </div>
                      <div className={details_css.list_details_bottom_wrap}>
                        {/* title */}
                        <label>Title</label>
                        <input
                          style={{
                            borderColor: this.state.titleError ? "red" : "#eee",
                          }}
                          type="text"
                          placeholder="should be 5-50 characters"
                          className={[`form-control  mt-2 `]}
                          value={this.state.title}
                          onChange={(event) =>
                            this.setState({
                              title: event.target.value,
                            })
                          }
                        />
                        {/* description */}
                        <label>Description</label>
                        <textarea
                          cols="30"
                          rows="5"
                          type="text"
                          placeholder="should be 5-1000 characters"
                          className={[`form-control  mt-2`]}
                          style={{
                            borderColor: this.state.descriptionError
                              ? "red"
                              : "#eee",
                          }}
                          value={this.state.description}
                          onChange={(event) =>
                            this.setState({
                              description: event.target.value,
                            })
                          }
                        ></textarea>
                        {/* select category */}
                        <label>Category</label>
                        <div className={details_css.change_category}>
                          <select
                            className={[`form-select mt-2`]}
                            aria-label="Default select example"
                            style={{
                              borderColor: this.state.categoryError
                                ? "red"
                                : "#eee",
                            }}
                            onChange={(e) =>
                              this.setState({
                                category: e.target.value,
                              })
                            }
                          >
                            <option disabled>{"==>Change Category<==="}</option>
                            <option
                              selected={cis_selected("Dresses")}
                              value="Dresses"
                            >
                              Dresses
                            </option>
                            <option
                              selected={cis_selected("Coats/Jackets")}
                              value="Coats/Jackets"
                            >
                              Coats/Jackets
                            </option>
                            <option
                              selected={cis_selected("Trousers/Skirts")}
                              value="Trousers/Skirts"
                            >
                              Trousers/Skirts
                            </option>
                            <option
                              selected={cis_selected("Accessories")}
                              value="Accessories"
                            >
                              Accessories
                            </option>
                          </select>
                        </div>
                        {/* select color */}
                        <label>Color</label>
                        <div className={details_css.change_category}>
                          <select
                            className={[`form-select mt-2 `]}
                            style={{
                              borderColor: this.state.colorError
                                ? "red"
                                : "#eee",
                            }}
                            aria-label="Default select example"
                            onChange={(e) =>
                              this.setState({
                                color: e.target.value,
                              })
                            }
                          >
                            <option disabled>{"==>Change Color<==="}</option>
                            {color_codes.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  selected={Color_selected(item.value)}
                                  value={item.value}
                                >
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {/* select size */}
                        <label>Size</label>
                        <div className={details_css.change_category}>
                          <select
                            className={[`form-select mt-2`]}
                            style={{
                              borderColor: this.state.sizeError
                                ? "red"
                                : "#eee",
                            }}
                            aria-label="Default select example"
                            onChange={(e) =>
                              this.setState({
                                size: e.target.value,
                              })
                            }
                          >
                            <option disabled>{"==>Change Color<==="}</option>
                            {sizes.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  selected={Size_selected(item)}
                                  value={item}
                                >
                                  {`UK${item}`}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {/* brand */}
                        <label>Brand</label>
                        <input
                          type="text"
                          placeholder="minimum 3 characters"
                          className={[`form-control  mt-2`]}
                          style={{
                            borderColor: this.state.brandError ? "red" : "#eee",
                          }}
                          value={this.state.brand}
                          onChange={(event) =>
                            this.setState({
                              brand: event.target.value.toUpperCase(),
                            })
                          }
                        />
                        {/* pricing */}
                        <div className={details_css.list_details_pricing_wrap}>
                          {/* pricing item */}
                          <div className={details_css.listd_pricing_item_wrap}>
                            <label>Day3</label>
                            <div className={details_css.listd_pricing_item}>
                              <span>{this.state.pricing?.currency}</span>
                              <input
                                type="number"
                                placeholder="minimum 20"
                                className={[`form-control  mt-2 mb-2`]}
                                style={{
                                  borderColor: this.state.pricing3Error
                                    ? "red"
                                    : "#eee",
                                }}
                                value={this.state.pricing.day3}
                                onChange={(event) =>
                                  this.setState({
                                    pricing: {
                                      ...this.state.pricing,
                                      day3: Number(event.target.value),
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                          {/* pricing item */}
                          <div className={details_css.listd_pricing_item_wrap}>
                            <label>Day5</label>
                            <div className={details_css.listd_pricing_item}>
                              <span>{this.state.pricing?.currency}</span>
                              <input
                                type="number"
                                placeholder="minimum 20"
                                className={[`form-control  mt-2 mb-2`]}
                                style={{
                                  borderColor: this.state.pricing5Error
                                    ? "red"
                                    : "#eee",
                                }}
                                value={this.state.pricing.day5}
                                onChange={(event) =>
                                  this.setState({
                                    pricing: {
                                      ...this.state.pricing,
                                      day5: Number(event.target.value),
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                          {/* pricing item */}
                          <div className={details_css.listd_pricing_item_wrap}>
                            <label>Day10</label>
                            <div className={details_css.listd_pricing_item}>
                              <span>{this.state.pricing?.currency}</span>
                              <input
                                type="number"
                                placeholder="minimum 20"
                                className={[`form-control  mt-2 mb-2`]}
                                style={{
                                  borderColor: this.state.pricing10Error
                                    ? "red"
                                    : "#eee",
                                }}
                                value={this.state.pricing.day10}
                                onChange={(event) =>
                                  this.setState({
                                    pricing: {
                                      ...this.state.pricing,
                                      day10: Number(event.target.value),
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <label>Shipping fees</label>
                        {/* shipping */}
                        <div className={details_css.listd_pricing_item}>
                          <span>{this.state.pricing?.currency}</span>
                          <input
                            type="number"
                            placeholder=""
                            className={[`form-control  mt-2`]}
                            style={{
                              borderColor: this.state.shippingError
                                ? "red"
                                : "#eee",
                            }}
                            value={this.state.shipping}
                            onChange={(event) =>
                              this.setState({
                                shipping: Number(event.target.value),
                              })
                            }
                          />
                        </div>
                        {/* retail */}
                        <label>Rtail Price</label>
                        <div className={details_css.listd_pricing_item}>
                          <span>{this.state.pricing?.currency}</span>
                          <input
                            type="number"
                            placeholder="minimum 100"
                            className={[`form-control  mt-2`]}
                            style={{
                              borderColor: this.state.retailError
                                ? "red"
                                : "#eee",
                            }}
                            value={this.state.retail}
                            onChange={(event) =>
                              this.setState({
                                retail: Number(event.target.value),
                              })
                            }
                          />
                        </div>
                        {/* action button */}
                        <div style={{ margin: "20px 0" }}>
                          <button
                            disabled={this.state.details.status === "deleted"}
                            onClick={() =>
                              delete_list(this.state.details.list_key)
                            }
                            style={{
                              marginLeft: 10,
                              cursor: "pointer",
                              minWidth: 200,
                            }}
                            className={`btn ${
                              this.state.details.status === "deleted"
                                ? "btn-secondary"
                                : "btn-danger"
                            } btn-sm ml-2`}
                          >
                            {this.state.details.status === "deleted"
                              ? "Deleted"
                              : "Delete"}
                          </button>
                          <button
                            onClick={() => save_changes()}
                            style={{
                              marginLeft: 10,
                              cursor: "pointer",
                              minWidth: 200,
                            }}
                            className="btn btn-success btn-sm ml-2"
                          >
                            save changes
                          </button>
                          {/*  */}
                        </div>
                        <div className={details_css.list_reviews_wrap}>
                          <h6 className={details_css.review_label}>Reviews</h6>
                          {details.reviews.map((item, index) => {
                            return <Review key={index} review={item} />;
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Listdetails);
