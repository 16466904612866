// modules

let is_development = false;
const header = (token, egnore = false, verify_check = false) => {
  const header_make = {
    headers: {
      Authorization: `Bearer ${token}`,
      default: true,
      auth_egnore: egnore,
      verifycheck: verify_check,
    },
  };
  return header_make;
};

const formDatadeader = (token, egnore = false, verify_check = false) => {
  const header_make = new Headers({
    Authorization: `Bearer ${token}`,
    "content-type": "multipart/form-data",
    default: true,
    auth_egnore: egnore,
    verifycheck: verify_check,
  });
  return header_make;
};

export const Commondata = {
  url: is_development
    ? "http://localhost:8000"
    : "https://cdns-server.ninthlife.eu",
  version: is_development ? "0.1.0.4" : "0.1.0.3_dev",
  access_key: "a453e98e5a7317efdfe4b1e2FF524",
  header: header,
  formDatadeader: formDatadeader,
};

export const INITIAL_STATE = {
  accessToken: null,
  version: "ninthweb1.0",
  loading: false,
  Commondata: Commondata,
};
