import React, { Component, useState } from "react";
import "../../css/coupon.css";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import Spinner from "../../component/Spinner";
import Selfspinner from "../../component/Selfspinner";
import dateFormat from "dateformat";
import { connect } from "react-redux";
import dateValidator from "../../component/dateValidator";
// dispatch actions
import { Couponsget } from "../../redux/actionCreator/Coreactions";
import { Couponcreate } from "../../redux/actionCreator/Coreactions";
import { Couponupdate } from "../../redux/actionCreator/Coreactions";
import { Usersget } from "../../redux/actionCreator/Useractions";
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Couponsget_dis: (token, setState) => dispatch(Couponsget(token, setState)),
    Couponcreate_dis: (token, data, setState, state) =>
      dispatch(Couponcreate(token, data, setState, state)),
    Couponupdate_dis: (token, data, callback) =>
      dispatch(Couponupdate(token, data, callback)),
    Usersget_dis: (token, setState, kw, ld) =>
      dispatch(Usersget(token, setState, kw, ld)),
  };
};
export class Cupons extends Component {
  constructor() {
    super();
    this.todayobj = new Date();
    this.todayobjnew = new Date();
    this.tomorobj = this.todayobjnew.setDate(this.todayobjnew.getDate() + 1);
    this.state = {
      coupons: [],
      users: [],
      displaySearch: false,
      loading: false,
      loadingcre: false,
      loadingdis: false,
      loadingsave: false,
      loadingdel: false,
      user: null,
      code: "",
      amount: "",
      discountType: "constant",
      availability: "specific",
      users: [],
      start: `${dateFormat(this.todayobj, "yyyy-mm-dd")}T${dateFormat(
        this.todayobj,
        "HH:MM"
      )}`,
      expire: `${dateFormat(this.tomorobj, "yyyy-mm-dd")}T${dateFormat(
        this.todayobj,
        "HH:MM"
      )}`,
      codeError: false,
      amountError: false,
      startError: false,
      expireError: false,
      userError: false,
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.props.Couponsget_dis(this.props.accessToken, this.setState);
  }
  render() {
    const onChnagestate = (e, key) => {
      this.setState({
        [key]: e.target.value,
      });
    };

    const check_create_error = () => {
      let error = false;
      const make_error = (sname, type) => {
        this.setState({
          [sname]: type,
        });
        if (type === true) {
          error = true;
        }
      };
      // check cupon code
      if (typeof this.state.code === "string" && this.state.code.length > 3) {
        make_error("codeError", false);
      } else {
        make_error("codeError", true);
      }
      // check amount
      if (
        typeof Number(this.state.amount) === "number" &&
        Number(this.state.amount) > 0 &&
        !isNaN(Number(this.state.amount))
      ) {
        make_error("amountError", false);
      } else {
        make_error("amountError", true);
      }

      // check start
      if (dateValidator(this.state.start)) {
        make_error("startError", false);
      } else {
        make_error("startError", true);
      }
      // expire check
      if (dateValidator(this.state.expire)) {
        make_error("expireError", false);
      } else {
        make_error("expireError", true);
      }
      //  check user
      if (this.state.availability === "specific" && !this.state.user?.id) {
        make_error("userError", true);
      } else {
        make_error("userError", false);
      }
      return error;
    };

    const create_coupon = () => {
      if (!check_create_error()) {
        const data = {
          user_id: this.state.user?.id,
          amount: Number(this.state.amount),
          code: this.state.code.toUpperCase(),
          start: this.state.start,
          expire: this.state.expire,
          availability: this.state.availability,
          discountType: this.state.discountType,
        };
        this.props.Couponcreate_dis(
          this.props.accessToken,
          data,
          this.setState,
          this.state
        );
      }
    };

    const ChangeStateItem = (cobj) => {
      let coupons = [];
      for (let it of this.state.coupons) {
        if (cobj.id !== it.id) {
          coupons.push(it);
        } else {
          coupons.push(cobj);
        }
      }
      this.setState({
        coupons: coupons,
      });
    };

    const Coupon_delete_state = (id) => {
      const updated_coupons = [];
      for (let cou of this.state.coupons) {
        if (cou.id !== id) {
          updated_coupons.push(cou);
        }
      }
      this.setState({
        coupons: updated_coupons,
      });
    };

    const searchUser = (event, update = true) => {
      this.setState({
        userSearch: update ? event.target.value : "",
        displaySearch: true,
      });
      this.props.Usersget_dis(
        this.props.accessToken,
        this.setState,
        update ? event.target.value : "",
        false
      );
    };
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Coupons" />
          <Contentwrap>
            <Header path="Coupons" subpath="home/coupons" />
            <div className="section">
              <h4 className="section_label">Create Coupons</h4>
              <div className="section_body">
                <div className="cupon-creitem-wrap">
                  <label>coupon CODE</label>
                  <input
                    value={this.state.code}
                    onChange={(e) => onChnagestate(e, "code")}
                    placeholder="e.g TEST05"
                    className="form-control coupon-code-input"
                    style={{
                      borderColor: this.state.codeError ? "red" : "grey",
                    }}
                    type="text"
                    required
                  />
                </div>
                <div className="cupon-creitem-wrap">
                  <label>
                    coupon{" "}
                    {this.state.discountType === "constant"
                      ? "amount"
                      : "Percent"}
                  </label>
                  <input
                    value={this.state.amount}
                    onChange={(e) => onChnagestate(e, "amount")}
                    placeholder="e.g 20"
                    className="form-control"
                    style={{
                      borderColor: this.state.amountError ? "red" : "grey",
                    }}
                    type="number"
                    required
                  />
                </div>
                <div className="cupon-creitem-wrap">
                  <label>discount type</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => onChnagestate(e, "discountType")}
                    value={this.state.discountType}
                  >
                    <option
                      selected={this.state.discountType === "constant"}
                      value="constant"
                    >
                      Constant amount
                    </option>
                    <option
                      selected={this.state.discountType === "percentage"}
                      value="percentage"
                    >
                      Percentage
                    </option>
                  </select>
                </div>

                <div className="cupon-creitem-wrap">
                  <label>Available Rent</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => onChnagestate(e, "availability")}
                  >
                    <option
                      selected={this.state.availability === "specific"}
                      value="specific"
                    >
                      Specific User
                    </option>
                    <option
                      selected={this.state.availability === "firstorder"}
                      value="firstorder"
                    >
                      New User(First Order)
                    </option>

                    <option
                      selected={this.state.availability === "alltimes"}
                      value="alltimes"
                    >
                      All Users
                    </option>
                  </select>
                </div>
                {this.state.availability === "specific" && (
                  <div className="cupon-creitem-wrap">
                    <label>Search a User</label>
                    {this.state.user ? (
                      <input
                        value={`email:${this.state.user?.email} ==> username:${this.state.user?.username} ==> name:${this.state.user?.name}`}
                        onFocus={(e) => {
                          this.setState({
                            user: null,
                            userSearch: "",
                          });
                          searchUser(e, false);
                        }}
                        placeholder="Search a user by username , name or email address"
                        className="form-control"
                        style={{
                          borderColor: this.state.userError ? "red" : "grey",
                        }}
                        type="text"
                        required
                      />
                    ) : (
                      <input
                        value={this.state.userSearch}
                        onChange={(e) => searchUser(e)}
                        onFocus={(e) => searchUser(e)}
                        onBlur={() => {
                          // setTimeout(() => {
                          //   this.setState({ displaySearch: false });
                          // }, 400);
                        }}
                        placeholder="Search a user by username , name or email address"
                        className="form-control"
                        style={{
                          borderColor: this.state.userError ? "red" : "grey",
                        }}
                        type="text"
                        required
                      />
                    )}
                    {this.state.displaySearch &&
                      this.state.users.length > 0 && (
                        <div className="coupon-user-search-result">
                          {this.state.displaySearch && (
                            <>
                              <table
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                  borderSpacing: 0,
                                }}
                              >
                                <tr>
                                  <th>Email</th>
                                  <th>Username</th>
                                  <th>Name</th>
                                </tr>
                                {this.state.users.map((outUser, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      onClick={() => {
                                        this.setState({ user: outUser });
                                        this.setState({
                                          displaySearch: false,
                                        });
                                      }}
                                      className="coupon-user-select-item"
                                    >
                                      <td>{outUser.email}</td>
                                      <td>{outUser.username}</td>
                                      <td>{outUser.name}</td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </>
                          )}
                        </div>
                      )}
                  </div>
                )}

                <div className="cupon-creitem-wrap">
                  <label>coupon Start</label>
                  <input
                    onChange={(e) => onChnagestate(e, "start")}
                    value={this.state.start}
                    type="datetime-local"
                    className="form-control"
                    style={{
                      borderColor: this.state.startError ? "red" : "grey",
                    }}
                    inputMode="none"
                    required
                  ></input>
                </div>
                <div className="cupon-creitem-wrap">
                  <label>coupon expire</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    style={{
                      borderColor: this.state.expireError ? "red" : "grey",
                    }}
                    required
                    value={this.state.expire}
                    onChange={(e) => onChnagestate(e, "expire")}
                  ></input>
                </div>
                <button
                  disabled={this.state.loadingcre}
                  className="btn btn-success btn-lg coupon-crebtn"
                  onClick={create_coupon}
                >
                  {this.state.loadingcre ? <Selfspinner /> : "create coupon"}
                </button>
              </div>
            </div>
            <div className="section">
              <h4 className="section_label">Coupons</h4>
              <div className="section_body">
                <div className="coupon-item-wrap">
                  <div className={`table-responsive`}>
                    <table className="table table-hover table_change">
                      <thead>
                        <tr>
                          <th scope="col" style={{ minWidth: 120 }}>
                            code
                          </th>
                          <th scope="col" style={{ minWidth: 100 }}>
                            amount
                          </th>
                          <th scope="col" style={{ minWidth: 200 }}>
                            discount type
                          </th>
                          <th scope="col" style={{ minWidth: 200 }}>
                            Available Rent
                          </th>
                          <th scope="col" style={{ minWidth: 200 }}>
                            start
                          </th>
                          <th scope="col" style={{ minWidth: 200 }}>
                            expire
                          </th>
                          <th scope="col" style={{ minWidth: 290 }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.coupons.map((item, index) => {
                          return (
                            <Couponitem
                              key={index}
                              state={this.state}
                              setState={this.setState}
                              ChangeStateItem={ChangeStateItem}
                              Couponupdate_dis={this.props.Couponupdate_dis}
                              Coupon_delete_state={Coupon_delete_state}
                              accessToken={this.props.accessToken}
                              pprops={this.props}
                              item={item}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

const Couponitem = (props) => {
  const [item, setItem] = useState(props.item);
  const [edit, setEdit] = useState(false);
  const [loadingsv, setLoadingsv] = useState(false);
  const [loadingdis, setLoadingdis] = useState(false);
  const [loadingdl, setLoadingdl] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [startError, setStartError] = useState(false);
  const [expireError, setExpireError] = useState(false);
  const error_fucs = {
    setCodeError: (type) => setCodeError(type),
    setAmountError: (type) => setAmountError(type),
    setStartError: (type) => setStartError(type),
    setExpireError: (type) => setExpireError(type),
  };
  const coupon_update_callback = (type, data) => {
    setLoadingsv(false);
    setLoadingdis(false);
    if (type === true) {
      setItem({
        ...data,
      });
      props.ChangeStateItem({
        ...data,
      });
      setEdit(false);
    } else {
      alert(data);
    }
  };

  const check_create_error = () => {
    let error = false;
    const make_error = (sname, type) => {
      error_fucs["setAmountError"](type);
      if (type === true) {
        error = true;
      }
    };
    // check cupon code
    if (typeof item.code === "string" && item.code.length > 3) {
      make_error("setCodeError", false);
    } else {
      make_error("setCodeError", true);
    }
    // check amount
    if (
      typeof Number(item.amount) === "number" &&
      Number(item.amount) > 0 &&
      !isNaN(Number(item.amount))
    ) {
      make_error("setAmountError", false);
    } else {
      make_error("setAmountError", true);
    }

    // check start
    if (dateValidator(item.start)) {
      make_error("setStartError", false);
    } else {
      make_error("setStartError", true);
    }
    // expire check
    if (dateValidator(item.expire)) {
      make_error("setExpireError", false);
    } else {
      make_error("setExpireError", true);
    }
    return error;
  };
  const update_coupon = () => {
    if (!check_create_error()) {
      const data = {
        ...item,
        user_id: null,
      };
      props.Couponupdate_dis(props.accessToken, data, coupon_update_callback);
      setLoadingsv(true);
    }
  };

  const coupon_deactivate = () => {
    const data = {
      ...props.item,
      user_id: null,
      status: props.item.status === "active" ? "deactive" : "active",
    };
    props.Couponupdate_dis(props.accessToken, data, coupon_update_callback);
    setLoadingdis(true);
  };

  const delete_coupon_callback = (type, data) => {
    setLoadingdl(false);
    if (type === true) {
      props.Coupon_delete_state(data.id);
    } else {
      alert(data);
    }
  };
  const coupon_delete = () => {
    setLoadingdl(true);
    const data = {
      ...props.item,
      user_id: null,
      status: "delete",
    };
    props.Couponupdate_dis(props.accessToken, data, delete_coupon_callback);
  };
  return (
    <tr key={props.index}>
      <td>
        {edit ? (
          <input
            value={item.code}
            onChange={(e) =>
              setItem({
                ...item,
                code: e.target.value,
              })
            }
            placeholder="e.g TEST05"
            className="form-control coupon-code-input"
            style={{
              borderColor: codeError ? "red" : "grey",
            }}
            type="text"
            required
          />
        ) : (
          item.code
        )}
      </td>
      <td>
        {" "}
        {edit ? (
          <input
            value={item.amount}
            onChange={(e) =>
              setItem({
                ...item,
                amount: e.target.value,
              })
            }
            placeholder="e.g 20"
            className="form-control"
            style={{
              borderColor: amountError ? "red" : "grey",
            }}
            type="number"
            required
          />
        ) : (
          item.amount
        )}
      </td>

      <td>
        {edit ? (
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) =>
              setItem({
                ...item,
                discountType: e.target.value,
              })
            }
            value={item.discountType}
          >
            <option
              selected={item.discountType === "constant"}
              value="constant"
            >
              Constant amount
            </option>
            <option
              selected={item.discountType === "percentage"}
              value="percentage"
            >
              Percentage
            </option>
          </select>
        ) : (
          item.discountType
        )}
      </td>
      <td>
        {edit ? (
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) =>
              setItem({
                ...item,
                availability: e.target.value,
              })
            }
            value={item.availability}
          >
            <option
              selected={item.availability === "firstorder"}
              value="firstorder"
            >
              New User(First Order)
            </option>
            <option
              selected={item.availability === "alltimes"}
              value="alltimes"
            >
              All Users
            </option>
          </select>
        ) : (
          item.availability
        )}
      </td>
      <td>
        {edit ? (
          <input
            onChange={(e) =>
              setItem({
                ...item,
                start: e.target.value,
              })
            }
            value={`${dateFormat(item.start, "yyyy-mm-dd")}T${dateFormat(
              item.start,
              "HH:MM"
            )}`}
            type="datetime-local"
            className="form-control"
            style={{
              borderColor: startError ? "red" : "grey",
            }}
            inputMode="none"
            required
          ></input>
        ) : (
          dateFormat(item.start, "yyyy-mm-dd hh:MM TT")
        )}
      </td>
      <td className="min_width300">
        {edit ? (
          <input
            type="datetime-local"
            className="form-control"
            style={{
              borderColor: expireError ? "red" : "grey",
            }}
            required
            value={`${dateFormat(item.expire, "yyyy-mm-dd")}T${dateFormat(
              item.expire,
              "HH:MM"
            )}`}
            onChange={(e) =>
              setItem({
                ...item,
                expire: e.target.value,
              })
            }
          ></input>
        ) : (
          dateFormat(item.expire, "yyyy-mm-dd hh:MM TT")
        )}
      </td>
      <td>
        <button
          disabled={loadingdis || loadingdl || loadingsv}
          onClick={() => {
            if (edit === true) {
              update_coupon();
            } else {
              setEdit(true);
            }
          }}
          className="btn btn-primary coupon-it-button"
        >
          {loadingsv === true ? (
            <Selfspinner size={26} />
          ) : edit ? (
            "save"
          ) : (
            "edit"
          )}
        </button>
        <button
          disabled={loadingdis || loadingdl || loadingsv}
          onClick={() => coupon_deactivate()}
          className={`btn coupon-it-button ${
            item.status === "active" ? "btn-success" : "btn-danger"
          }`}
        >
          {loadingdis ? (
            <Selfspinner size={26} />
          ) : item.status === "active" ? (
            "activated"
          ) : (
            "deactivate"
          )}
        </button>
        <button
          disabled={loadingdis || loadingdl || loadingsv}
          onClick={() => coupon_delete()}
          className="btn btn-danger coupon-it-button"
        >
          {loadingdl ? <Selfspinner size={26} /> : "delete"}
        </button>
      </td>
    </tr>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Cupons);
