import React, { Component } from "react";
import "../css/wrap.css";
export class Wrap extends Component {
  render() {
    return (
      <div className="body">
        {this.props.children ? this.props.children : null}
      </div>
    );
  }
}

export default Wrap;
