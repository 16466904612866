import React, { Component } from "react";
import { connect } from "react-redux";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Sidebar from "../../component/Sidebar";
import Header from "../../component/Header";
import dateFormat from "dateformat";
import Selfspinner from "../../component/Selfspinner";
import Spinner from "../../component/Spinner";
// dispatch actions
import { Reportsget } from "../../redux/actionCreator/Coreactions";
import { Reportsgetloadmore } from "../../redux/actionCreator/Coreactions";
import { Report_update } from "../../redux/actionCreator/Coreactions";

const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Reportsget_dis: (token, setState, state) =>
      dispatch(Reportsget(token, setState, state)),
    Reportsgetloadmore_dis: (token, setState, state) =>
      dispatch(Reportsgetloadmore(token, setState, state)),
    Report_update_dis: (token, data, setState, state) =>
      dispatch(Report_update(token, data, setState, state)),
  };
};

export class Reports extends Component {
  constructor() {
    super();
    this.state = {
      reports: [],
      loading: false,
      loadingmore: false,
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.props.Reportsget_dis(this.props.accessToken, this.setState);
  }
  render() {
    const load_more = () => {
      this.props.Reportsgetloadmore_dis(
        this.props.accessToken,
        this.setState,
        this.state
      );
    };

    const Report_update = (report_id, status) => {
      const data = {
        report_id: report_id,
        report_status: status,
      };
      this.props.Report_update_dis(
        this.props.accessToken,
        data,
        this.setState,
        this.state
      );
    };
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Reports" />
          <Contentwrap>
            <Header path="Reports" subpath="home/reports" />
            <div className="section">
              <h3 className="section_label">Reports</h3>
              <div className="section_body">
                <div className={`table-responsive`}>
                  <table className="table table-hover table_change">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th className="min_width250" scope="col">
                          username
                        </th>
                        <th className="min_width250" scope="col">
                          report for
                        </th>
                        <th className="min_width250" scope="col">
                          reason
                        </th>
                        <th className="min_width250" scope="col">
                          list
                        </th>
                        <th scope="col">status</th>
                        <th scope="col">requested time</th>

                        <th scope="col">_</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.reports.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.username}</td>
                            <td>{item.reporting_for}</td>
                            <td>{item.report_reason}</td>
                            <td>
                              <a
                                style={{ textDecoration: "none" }}
                                href={`/lists/details?list=${item.report_listkey}`}
                              >
                                See List
                              </a>
                            </td>
                            <td>{item.status}</td>
                            <td>
                              {dateFormat(item.create, "dd mmm yy h:MM TT")}
                            </td>
                            <td className="report-button-actions">
                              <button
                                onClick={() =>
                                  Report_update(
                                    item.id,
                                    item.status === "complete"
                                      ? "pending"
                                      : "complete"
                                  )
                                }
                                style={{
                                  backgroundColor:
                                    item.status === "complete"
                                      ? "#328761"
                                      : "#507ec4",
                                }}
                              >
                                {item.status === "complete"
                                  ? "Solved"
                                  : "Solve"}
                              </button>
                              <button
                                onClick={() =>
                                  Report_update(
                                    item.id,
                                    item.status === "seen" ? "pending" : "seen"
                                  )
                                }
                                style={{
                                  backgroundColor:
                                    item.status === "seen" ||
                                    item.status === "complete"
                                      ? "#328761"
                                      : "#507ec4",
                                }}
                              >
                                {item.status === "pending" ? "Seen" : "Unseen"}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="pb-3">
                {/* <Selfspinner /> */}
                {this.state.loadingmore ? (
                  <div className="load_more_spinner_wrap">
                    <Selfspinner />
                  </div>
                ) : (
                  <div onClick={() => load_more()} className="loadmore_btn">
                    Load more
                  </div>
                )}
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
