import React, { Component } from "react";
import "../css/wrap.css";
export class Flexwrap extends Component {
  render() {
    return (
      <div className="body_flex_wrap">
        {this.props.children ? this.props.children : null}
      </div>
    );
  }
}

export default Flexwrap;
