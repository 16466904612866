import React from "react";
import review_css from "../css/review.module.css";
import Rating from "./Rating";
const Review = ({ review }) => {
  return (
    <div className={review_css.review_wrap}>
      <img
        className={review_css.review_user_picture}
        src={review.picture}
        alt=""
      />
      <div className={review_css.review_header_right}>
        <h6 className={review_css.review_user_name}>{review.name}</h6>
        <Rating rate={review.rating} />
        <p className={review_css.review_message}>{review.message}</p>
      </div>
    </div>
  );
};

export default Review;
