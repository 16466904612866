import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../css/sidebar.css";
import { manues } from "../core";

// dispatch actions
import { Logout } from "../redux/actionCreator/Authactions";
import { Reportunseenget } from "../redux/actionCreator/Coreactions";

const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    Logout_dis: (token, props) => dispatch(Logout(token, props)),
    Reportunseenget_dis: (token, setState) =>
      dispatch(Reportunseenget(token, setState)),
  };
};

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_hide: false,
      active: tryactive(props),
      rports: "...",
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.props.Reportunseenget_dis(this.props.accessToken, this.setState);
  }
  render() {
    const is_hide = this.state.is_hide;
    const active = this.state.active;
    const isunseen = (name, type) => {
      if (name === "Reports" && type === "main") {
        return true;
      } else {
        return false;
      }
    };
    return (
      <div className="sidebar_wrap">
        <div
          className={`sidebar_menu ${
            is_hide ? "hide_sidebar" : "show_sidebar"
          }`}
        >
          <ul className="side_bar_menu_wrap">
            {manues.map((item, index) => {
              return (
                <Link
                  to={`${item.url}`}
                  className="sdb_main_menu_link"
                  key={index}
                >
                  <li
                    onClick={() => {
                      if (item.main !== "Logout") {
                        this.setState({
                          active: item.main,
                        });
                      } else {
                        this.props.Logout_dis(
                          this.props.accessToken,
                          this.props
                        );
                      }
                    }}
                    className={`side_bar_main_menu ${
                      active === item.main ? "sdb_m_menu_active" : null
                    }`}
                  >
                    <div className="side_bar_mnu_iclb ">
                      <i className={`${item.icon} sdb_menu_ic`}></i>
                      <p>
                        {item.main}{" "}
                        {isunseen(item.main, "main") ? (
                          <span className="number-of-unseen">
                            {this.state.rports}
                          </span>
                        ) : null}
                      </p>
                    </div>
                    <i
                      style={{
                        color: item.sub.length > 0 ? "#fff" : "grey",
                      }}
                      className={`fa-solid fa-angle-${
                        active === item.main ? "down" : "right"
                      } sdb_menu_ic`}
                    ></i>
                  </li>
                  {/* sub menu */}
                  {active === item.main ? (
                    <>
                      {item.sub.map((itemsub, indexsub) => {
                        return (
                          <Link
                            to={`${itemsub.url}`}
                            key={indexsub}
                            className="side_bar_sub_menu"
                          >
                            <p>{itemsub.name} </p>
                          </Link>
                        );
                      })}
                    </>
                  ) : null}

                  {/* sub menu */}
                </Link>
              );
            })}
          </ul>
        </div>
        <div
          onClick={() => this.setState({ is_hide: !is_hide })}
          className={`sdb_naviagte_btn_wrap  ${
            is_hide ? "sdb_hnv_btn_wrap" : null
          }`}
        >
          <i
            className={`fa-solid fa-angle-${
              is_hide ? "right" : "left"
            } sdb_menu_ic`}
          ></i>
        </div>
      </div>
    );
  }
}

const tryactive = (props) => {
  try {
    const active = props.active;
    return active;
  } catch {
    return "";
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
