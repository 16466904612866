import React from "react";
import spinner_css from "../css/spinner.module.css";
const Spinner = () => {
  return (
    <div className={spinner_css.spinner}>
      <div className="spinner-border text-info" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );
};

export default Spinner;
