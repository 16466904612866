

const Onstatus = time => {
    const nowtime = new Date().getTime();
    const created = new Date(time).getTime();
    const compare = new Date(nowtime - created);
    if (Math.floor(compare / (24 * 3600 * 1000 * 365)) < 1) {
        if (Math.floor(compare / (24 * 3600 * 1000 * 30)) < 1) {
            if (Math.floor(compare / (24 * 3600 * 1000 * 7)) < 1) {
                if (Math.floor(compare / (24 * 3600 * 1000)) < 1) {
                    if (Math.floor((compare / (1000 * 60 * 60)) % 24) < 1) {
                        if (Math.floor((compare / (1000 * 60)) % 60) < 1) {
                            if (Math.floor((compare / 1000) % 60) < 2) {
                                return 'Online';
                            } else {
                                return `Last seen: ${Math.floor(
                                    (compare / 1000) % 60,
                                )} second ago`;
                            }
                        } else {
                            if (Math.floor(
                                (compare / (1000 * 60)) % 60) < 2) {
                                return "Online"
                            } else {
                                return `Last seen: ${Math.floor(
                                    (compare / (1000 * 60)) % 60,
                                )} minite ago`;
                            }

                        }
                    } else {
                        return `Last seen: ${Math.floor(
                            (compare / (1000 * 60 * 60)) % 24,
                        )} hour ago`;
                    }
                } else {
                    return `Last seen: ${Math.floor(
                        compare / (24 * 3600 * 1000),
                    )} days ago`;
                }
            } else {
                return `Last seen: ${Math.floor(
                    compare / (24 * 3600 * 1000 * 7),
                )} week ago`;
            }
        } else {
            return `Last seen: ${Math.floor(
                compare / (24 * 3600 * 1000 * 30),
            )} month ago`;
        }
    } else {
        return `Last seen: ${Math.floor(
            compare / (24 * 3600 * 1000 * 365),
        )} years ago`;
    }
};

export default Onstatus;
