export const manues = [
  {
    main: "Home",
    url: "/",
    icon: "fa-solid fa-house",
    sub: [],
  },
  {
    main: "Users",
    icon: "fa-solid fa-user",
    url: "/users",
    sub: [
      //   { name: "Home sub1", url: "/sub/test" },
      //   { name: "Home sub1", url: "/sub/test" },
    ],
  },
  { main: "Sliders", icon: "fa-solid fa-photo-film", url: "/sliders", sub: [] },
  { main: "Lists", icon: "fa-solid fa-rectangle-list", url: "/lists", sub: [] },
  {
    main: "Rentals",
    icon: "fa-solid fa-sitemap",
    url: "/rentals",
    sub: [],
  },
  {
    main: "Notification",
    icon: "fa-solid fa-bell",
    url: "/notification",
    sub: [],
  },
  {
    main: "Coupons",
    icon: "fa-solid fa-percent",
    url: "/coupons",
    sub: [],
  },
  {
    main: "Reports",
    icon: "fa-solid fa-flag",
    url: "/reports",
    sub: [],
  },
  {
    main: "Setting",
    icon: "fa-solid fa-gear",
    url: "#",
    sub: [
      { name: "rent", url: "/setting/rent" },
      { name: "links", url: "/setting/link" },
      { name: "password", url: "/setting/change_password" },
    ],
  },
  {
    main: "Logout",
    icon: "fa-solid fa-arrow-right-from-bracket",
    url: "#",
    sub: [],
  },
];

export const home_sections = [
  { name: "Today", subsection: ["Hired", "Pending", "Checkout", "Payment"] },
  { name: "Last7", subsection: ["Hired", "Pending", "Checkout", "Payment"] },
  { name: "Last30", subsection: ["Hired", "Pending", "Checkout", "Payment"] },
  { name: "Last60", subsection: ["Hired", "Pending", "Checkout", "Payment"] },
];
