import React, { Component } from 'react'
import { connect } from 'react-redux';
import Wrap from '../../component/Wrap';
import Flexwrap from '../../component/Flexwrap';
import Contentwrap from '../../component/Contentwrap';
import Sidebar from '../../component/Sidebar';
import Header from '../../component/Header';
import Spinner from '../../component/Spinner';
import setting_css from '../../css/setting.module.css'
// dispatch actions
import { Changepasswordpost } from '../../redux/actionCreator/Authactions';
const mapStateToProps = (state) => {
    return {
        accessToken: state.accessToken
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        Changepasswordpost_dis: (token, data, setState) => dispatch(Changepasswordpost(token, data, setState))
    }
}
export class Changepassword extends Component {
    constructor() {
        super();
        this.state = {
            oldpassword: "",
            newpassword: "",
            confrimpassword: "",
            oldpasswordError: false,
            newpasswordError: false,
            confrimpasswordError: false,
            loading: false,
            error: ""
        };
        this.setState = this.setState.bind(this)
    }
    render() {
        const check_error = () => {
            let error = false
            const make_error = (name, value) => {
                this.setState({
                    [name]: value
                })
                if (value === true) {
                    error = true
                }

            }
            // check old password 
            if (this.state.oldpassword.length > 3) {
                make_error("oldpasswordError", false)
            } else {
                make_error("oldpasswordError", true)
            }
            // check new password 
            if (this.state.newpassword.length > 3) {
                make_error("newpasswordError", false)
            } else {
                make_error("newpasswordError", true)
            }
            // check confrim password 
            if (this.state.newpassword.length > 3 && (this.state.newpassword === this.state.confrimpassword)) {
                make_error("confrimpasswordError", false)
            } else {
                make_error("confrimpasswordError", true)
            }
            return error
        }
        const change_password = () => {
            if (!check_error()) {
                const data = {
                    old_password: this.state.oldpassword,
                    new_password: this.state.newpassword
                }
                this.props.Changepasswordpost_dis(this.props.accessToken, data, this.setState)
            }
        }
        return (
            <Wrap>
                <Flexwrap>
                    <Sidebar active="setting" />
                    <Contentwrap>
                        <Header path="Password" subpath="home/setting/password" />
                        <div className='change_password_wrap'>
                            <div style={{ width: '60%', margin: 'auto', padding: "0 10px", paddingBottom: 10, minWidth: 300 }} className='section'>
                                <h4 className='section_label'>Change Password</h4>
                                {this.state.error.length > 0 ? <p className={setting_css.error_notice}>{this.state.error}</p> : null}
                                <div className={setting_css.setting_wrap}>
                                    <label>Old password</label>
                                    <input
                                        className="form-control"
                                        style={{ borderColor: this.state.oldpasswordError ? "red" : "transparent" }}
                                        type="password"
                                        placeholder="ex: oldpassword123#"
                                        value={this.state.oldpassword}
                                        onChange={(e) => this.setState({ oldpassword: e.target.value })}

                                    />
                                </div>
                                <div className={setting_css.setting_wrap}>
                                    <label>New password</label>
                                    <input
                                        className="form-control"
                                        style={{ borderColor: this.state.newpasswordError ? "red" : "transparent" }}
                                        type="password"
                                        placeholder="ex: newpassword123#"
                                        value={this.state.newpassword}
                                        onChange={(e) => this.setState({ newpassword: e.target.value })}

                                    />
                                </div>
                                <div className={setting_css.setting_wrap}>
                                    <label>Confrim password</label>
                                    <input
                                        className="form-control"
                                        style={{ borderColor: this.state.confrimpasswordError ? "red" : "transparent" }}
                                        type="password"
                                        placeholder="ex: confrimpassword123#"
                                        value={this.state.confrimpassword}
                                        onChange={(e) => this.setState({ confrimpassword: e.target.value })}

                                    />
                                </div>
                                <div
                                    onClick={() => change_password()}
                                    style={{ margin: "10px 0" }}
                                    className={`btn btn-sm btn-success ${setting_css.save_setting_btn}`}
                                >
                                    Change password
                                </div>
                            </div>
                        </div>
                    </Contentwrap>
                    {this.state.loading ? <Spinner /> : null}
                </Flexwrap>
            </Wrap>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Changepassword);