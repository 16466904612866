import axios from "axios";
import * as actionTypes from "../actionTypes";
// import { header } from "../Initialstate";
import { Commondata } from "../Initialstate";
const header = Commondata.header;
const baseUrl = Commondata.url;
// const version = Commondata.version;
const access_key = Commondata.access_key;
// actions

export const Authentication = (data, setState, callback) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .post(`${baseUrl}/user/ad/auth/?secure_key=${access_key}`, data)
      .then((response) => {
        setState({
          loading: false,
        });
        callback(true, response.data);
      })
      .catch(function (err) {
        setState({
          loading: false,
        });
        callback(false, "authentication failed! try again.");
      });
  };
};

export const OTPverify = (token, otp, setState, callback) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl}/user/ad/auth/?otp=${otp}`, header(token))
      .then((response) => {
        setState({
          loading: false,
        });
        if (response.data.is_match) {
          localStorage.setItem("access_token", token);
          window.location.replace("/");
          dispatch(Auth_success({ access_key: token }));
          callback(true, response.data);
        } else {
          callback(false, "OTP doesn't match or expired!");
        }
      })
      .catch(function (err) {
        setState({
          loading: false,
        });
        callback(false, "OTP doesn't match or expired!");
      });
  };
};
const Auth_success = (data) => {
  return {
    type: actionTypes.AUTHENTICATION_SUCCESS,
    data: data,
  };
};

export const checkAuthentication = () => (dispatch) => {
  dispatch(Authloading());
  const access_token = localStorage.getItem("access_token");
  if (access_token !== null) {
    axios
      .get(`${baseUrl}/user/adcheckauth/`, header(access_token, false, true))
      .then((response) => {
        if (response.data.status === true) {
          dispatch(
            Auth_success({
              access_key: access_token,
            })
          );
        } else {
          dispatch({
            type: actionTypes.AUTH_FAILED,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.AUTH_FAILED,
        });
      });
  } else {
    dispatch(Authfailed());
  }
};

export const Logout = (token, props) => {
  return (dispatch) => {
    localStorage.removeItem("access_token");
    dispatch(LogoutSuccess());
    window.location.href = "/";
    axios
      .post(`${baseUrl}/user/super/logout/`, {}, header(token))
      .then((response) => {
        if (response.data.status === true) {
          localStorage.removeItem("access_token");
          dispatch(LogoutSuccess());
          window.location.href = "/";
        } else {
        }
      })
      .catch((err) => {});
  };
};

const LogoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
  };
};

const Authloading = () => {
  return {
    type: actionTypes.AUTH_LOADING,
  };
};

const Authfailed = () => {
  return {
    type: actionTypes.AUTH_FAILED,
  };
};

// change password
export const Changepasswordpost = (token, data, setState) => {
  setState({
    loading: true,
  });
  return (dispatch) => {
    axios
      .post(
        `${baseUrl}/user/super/change_password/`,
        data,
        header(token, false, true)
      )
      .then((response) => {
        if (response.data.status === true) {
          localStorage.removeItem("access_token");
          dispatch(LogoutSuccess());
          window.location.href = "/login";
        } else {
          setState({
            error: "failed! something wrong",
            loading: false,
          });
        }
      })
      .catch(function (error) {
        try {
          if (error.response.data.error_type !== "undefined") {
            setState({
              error: String(error.response.data.error_type),
              loading: false,
            });
          } else {
            setState({
              error: "failed! something wrong",
              loading: false,
            });
          }
        } catch {
          setState({
            error: "failed! something wrong",
            loading: false,
          });
        }
      });
  };
};
