import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
// pages
import Login from "../pages/auth/Login";
import Home from "../pages/home/Home";
import Users from "../pages/user/Users";
import Reports from "../pages/report/Reports";
import Details from "../pages/user/Details";
import Sliders from "../pages/core/Sliders";
import Lists from "../pages/list/Lists";
import Listdetails from "../pages/list/Listdetails";
import Rentals from "../pages/rental/Rentals";
import Rent from "../pages/setting/Rent";
import Links from "../pages/setting/Links";
import Changepassword from "../pages/setting/Changepassword";
import Coupons from "../pages/coupon/Coupons";
import Notification from "../pages/notification/Notification";
import Error from "../pages/error/Error";

function Checking() {
  return <div></div>;
}
// redux

const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
    loading: state.loading,
  };
};

const Router = (props) => {
  const pageAccess = (page) => {
    if (props.loading) {
      return Checking;
    } else {
      if (page === Login) {
        if (props.accessToken === null) {
          return Login;
        } else {
          return Error;
        }
      } else {
        if (props.accessToken !== null) {
          return page;
        } else {
          return Error;
        }
      }
    }
  };
  return (
    <>
      <Switch>
        <Route path="/login" component={pageAccess(Login)} exact />
        <Route path="/" component={pageAccess(Home)} exact />
        <Route path="/users" component={pageAccess(Users)} exact />
        <Route path="/users/details" component={pageAccess(Details)} exact />
        <Route path="/sliders" component={pageAccess(Sliders)} exact />
        <Route path="/lists" component={pageAccess(Lists)} exact />
        <Route
          path="/lists/details"
          component={pageAccess(Listdetails)}
          exact
        />
        <Route path="/rentals" component={pageAccess(Rentals)} exact />
        <Route path="/setting/rent" component={pageAccess(Rent)} exact />
        <Route path="/setting/link" component={pageAccess(Links)} exact />
        <Route path="/reports" component={pageAccess(Reports)} exact />
        <Route path="/coupons" component={pageAccess(Coupons)} exact />
        <Route
          path="/notification"
          component={pageAccess(Notification)}
          exact
        />
        <Route
          path="/setting/change_password"
          component={pageAccess(Changepassword)}
          exact
        />
        <Route path="*" component={pageAccess(Error)} exact />
      </Switch>
    </>
  );
};

export default connect(mapStateToProps)(Router);
