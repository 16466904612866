import React, { Component } from "react";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Sidebar from "../../component/Sidebar";
import Header from "../../component/Header";
import Spinner from "../../component/Spinner";
import Selfspinner from "../../component/Selfspinner";
import users_css from "../../css/user.module.css";
import dateFormat from "dateformat";
// dispatch actions
import { Usersget } from "../../redux/actionCreator/Useractions";
import { Userloadmore } from "../../redux/actionCreator/Useractions";
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Usersget_dis: (token, setState, kw, ld) =>
      dispatch(Usersget(token, setState, kw, ld)),
    Userloadmore_dis: (token, setState, state, kw, ld) =>
      dispatch(Userloadmore(token, setState, state, kw, ld)),
  };
};
export class Users extends Component {
  constructor() {
    super();
    this.state = {
      search_kw: "",
      users: [],
      loading: false,
      loadingld: false,
    };
    this.setState = this.setState.bind(this);
  }
  componentDidMount() {
    this.props.Usersget_dis(this.props.accessToken, this.setState, "");
  }
  render() {
    const search = () => {
      this.props.Usersget_dis(
        this.props.accessToken,
        this.setState,
        this.state.search_kw
      );
    };
    const load_more = () => {
      this.props.Userloadmore_dis(
        this.props.accessToken,
        this.setState,
        this.state,
        this.state.search_kw
      );
    };

    const export_exal = async () => {
      const export_json = [];
      for (let uitem of this.state.users) {
        const jsonobj = {
          "User First & Surname": uitem.name,
          Username: uitem.username,
          email: uitem.email,
          Location: uitem.address,
          "Number of Items for Hire": uitem.listed,
          "Number of Items Rented": uitem.num_rented,
          "Number of Reviews Given": uitem.gevien_rating.total,
          "Average Rating of Reviews Given": uitem.gevien_rating.average,
          "Number of Reviews Received": uitem.rating.total,
          "Average Rating of Reviews Received": uitem.rating.average,
          "Number of Followers": uitem.followers,
          "Number Following": uitem.following,
          "Join Date": uitem.join_date,
          "last login": uitem.last_login,
        };
        export_json.push(jsonobj);
      }
      const file_type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(export_json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const exel_buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([exel_buffer], { type: file_type });
      FileSaver.saveAs(
        data,
        `ninthlife_users_export${dateFormat(
          new Date(),
          "dd-mm-yyyy-HH:MM-TT"
        )}.xlsx`
      );
    };
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Users" />
          <Contentwrap>
            <Header path="Users" subpath="home/users" />
            <div className={users_css.users_wrap}>
              <div className={users_css.section}>
                <h4 className={users_css.section_label}>Search</h4>
                <div className={users_css.section_body}>
                  <div className={users_css.user_search_wrap}>
                    <input
                      type="text"
                      placeholder="search by name,username,email address"
                      className={`form-control ${users_css.user_search_input}`}
                      onChange={(e) =>
                        this.setState({
                          search_kw: e.target.value,
                        })
                      }
                      value={this.state.search_kw}
                    />
                    <button
                      className={`btn btn-primary ${users_css.user_search_btn}`}
                      onClick={() => search()}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{ position: "absolute" }}
                className={users_css.section}
              >
                <button
                  className={`${users_css.export_exal_btn} btn btn-success`}
                  onClick={() => export_exal()}
                >
                  export excel
                </button>
                <h4 className={users_css.section_label}>Users</h4>
                <div className={users_css.section_body}>
                  <div
                    className={`table-responsive ${users_css.user_table_wrap}`}
                  >
                    <table className="table table-hover table_change">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">username</th>
                          <th scope="col">name</th>
                          <th scope="col" className="min_width300">
                            email
                          </th>
                          <th scope="col" className="min_width300">
                            address
                          </th>
                          <th scope="col">listed</th>
                          <th scope="col">reviews</th>
                          <th scope="col">followers</th>
                          <th scope="col">following</th>
                          <th scope="col">status</th>
                          <th scope="col">_</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.users.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{String(item.username)}</td>
                              <td>{item.name}</td>
                              <td className="min_width300">{item.email}</td>
                              <td className="min_width300">
                                {String(item.address)}
                              </td>
                              <td>{item.listed}</td>
                              <td>{`${item.rating.total}(${item.rating.average}*)`}</td>
                              <td>{item.followers}</td>
                              <td>{item.following}</td>
                              <td>{item.status}</td>
                              <td>
                                <button
                                  onClick={() =>
                                    this.props.history.push(
                                      `/users/details?user=${item.store_id}`
                                    )
                                  }
                                  className={`btn btn-sm btn-success`}
                                >
                                  details
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {this.state.loadingld ? (
                    <div className="load_more_spinner_wrap">
                      <Selfspinner />
                    </div>
                  ) : (
                    <button
                      onClick={() => load_more()}
                      className={users_css.load_more}
                    >
                      Load more
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
