import React, { Component } from "react";
import { connect } from "react-redux";
import login_css from "../../css/login.module.css";
import Spinner from "../../component/Spinner";
import Otp from "./Otp";
// dispatch actions
import { Authentication } from "../../redux/actionCreator/Authactions";

const mapDispatchToProps = (dispatch) => {
  return {
    Authentication_dis: (data, setState, callback) =>
      dispatch(Authentication(data, setState, callback)),
  };
};
export class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      usernameError: false,
      passwordError: false,
      loading: false,
      access_key: "",
      is_otp: false,
      response: " ",
    };
    this.setState = this.setState.bind(this);
  }
  render() {
    const log_callback = (type, data) => {
      this.setState({
        loading: false,
      });
      if (type === true) {
        this.setState({
          is_otp: true,
          access_key: data.access_key,
        });
      } else {
        this.setState({
          response: data,
        });
      }
    };
    const login_submit = (event) => {
      event.preventDefault();
      //check user name
      if (this.state.username.trim().length < 3) {
        this.setState({
          usernameError: true,
        });
      } else {
        this.setState({
          usernameError: false,
        });
        if (this.state.password.trim().length > 0) {
          const data = {
            username: this.state.username,
            password: this.state.password,
          };
          this.props.Authentication_dis(data, this.setState, log_callback);
          this.setState({
            username: "",
            password: "",
            usernameError: false,
            passwordError: false,
          });
        }
      }
      //check password
      if (this.state.password.trim().length < 1) {
        this.setState({
          passwordError: true,
        });
      } else {
        this.setState({
          passwordError: false,
        });
      }
    };

    return (
      <>
        {!this.state.is_otp || this.state.access_key.trim().length < 1 ? (
          <div className={login_css.login}>
            <div className={login_css.login_box}>
              <h4 className={login_css.login_label}>Login</h4>
              {this.state.response.length > 0 ? (
                <p style={{ fontSize: 14, textAlign: "center", color: "red" }}>
                  {this.state.response}
                </p>
              ) : null}
              <div className={login_css.input_box}>
                <form
                  onSubmit={(event) => {
                    login_submit(event);
                  }}
                >
                  <div className={login_css.input_item}>
                    <label>user name</label>
                    <input
                      style={{
                        fontSize: 18,
                        fontWeight: "500",
                        borderColor: this.state.usernameError ? "red" : "#eee",
                      }}
                      onChange={(event) => {
                        this.setState({
                          username: event.target.value,
                        });
                      }}
                      value={this.state.username}
                      type="text"
                      placeholder="user name"
                      className="form-control control_log"
                    />
                  </div>
                  <div className={login_css.input_item}>
                    <label>password</label>
                    <input
                      style={{
                        fontSize: 18,
                        fontWeight: "700",
                        borderColor: this.state.passwordError ? "red" : "#eee",
                      }}
                      onChange={(event) => {
                        this.setState({
                          password: event.target.value,
                        });
                      }}
                      value={this.state.password}
                      type="password"
                      placeholder="password"
                      className="form-control control_log"
                    />
                  </div>
                  <button className={login_css.login_btn}>Login now</button>
                </form>
              </div>
            </div>
            {this.state.loading ? <Spinner /> : null}
          </div>
        ) : (
          <Otp gohome={this.props.history} access_key={this.state.access_key} />
        )}
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(Login);
