import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "./component/Spinner";
// dispatch actions
import Router from "./navigation/Router";
import { checkAuthentication } from "./redux/actionCreator/Authactions";

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthentication_dis: () => dispatch(checkAuthentication()),
  };
};
// navigation
export class App extends Component {
  constructor(props) {
    super(props);
    props.checkAuthentication_dis();
  }
  render() {
    return <>{this.props.loading ? <Spinner /> : <Router />}</>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
