import React, { Component } from "react";
import { connect } from "react-redux";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import Spinner from "../../component/Spinner";
import setting_css from "../../css/setting.module.css";
// dispatch actions
import { Rentsettingsget } from "../../redux/actionCreator/Coreactions";
import { Rentsettingspost } from "../../redux/actionCreator/Coreactions";
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    Rentsettingsget_dis: (token, setState) => dispatch(Rentsettingsget(token, setState)),
    Rentsettingspost_dis: (token, data, setState) => dispatch(Rentsettingspost(token, data, setState))
  }
}
export class Rent extends Component {
  constructor() {
    super();
    this.state = {
      approve_timeout: "",
      request_timeout: "",
      shipping_timeout: "",
      return_timeout: "",
      minimum_withdraw: "",
      charge: "",
      approveError: false,
      requestError: false,
      shippingError: false,
      returnError: false,
      chargeError: false,
      minimumError: false,
      loading: false
    };
    this.setState = this.setState.bind(this)
  }
  componentDidMount() {
    this.props.Rentsettingsget_dis(this.props.accessToken, this.setState)
  }
  render() {
    const check_error = () => {
      let error = false
      const makeerror = (name, value) => {
        this.setState({
          [name]: value
        })
        if (value === true) {
          error = true
        }
      }
      // check approve 
      if (String(this.state.approve_timeout).length > 0 && !isNaN(this.state.approve_timeout)) {
        makeerror("approveError", false)
      } else {
        makeerror("approveError", true)
      }
      // check request
      if (String(this.state.request_timeout).length > 0 && !isNaN(this.state.request_timeout)) {
        makeerror("requestError", false)
      } else {
        makeerror("requestError", true)
      }
      // check shipping
      if (String(this.state.shipping_timeout).length > 0 && !isNaN(this.state.shipping_timeout)) {
        makeerror("shippingError", false)
      } else {
        makeerror("shippingError", true)
      }
      // check return
      if (String(this.state.return_timeout).length > 0 && !isNaN(this.state.return_timeout)) {
        makeerror("returnError", false)
      } else {
        makeerror("returnError", true)
      }
      // check charge
      if (String(this.state.charge).length > 0 && !isNaN(this.state.charge)) {
        makeerror("chargeError", false)
      } else {
        makeerror("chargeError", true)
      }
      // check minimum
      if (String(this.state.minimum_withdraw).length > 0 && !isNaN(this.state.minimum_withdraw)) {
        makeerror("minimumError", false)
      } else {
        makeerror("minimumError", true)
      }
      return error
    }
    const save = () => {
      if (!check_error()) {
        const data = {
          approve_timeout: Number(this.state.approve_timeout),
          request_timeout: Number(this.state.request_timeout),
          shipping_timeout: Number(this.state.shipping_timeout),
          return_timeout: Number(this.state.return_timeout),
          minimum_withdraw: Number(this.state.minimum_withdraw),
          charge: Number(this.state.charge),
        }
        this.props.Rentsettingspost_dis(this.props.accessToken, data, this.setState)
      }
    }
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Setting" />
          <Contentwrap>
            <Header path="Rent" subpath="home/setting/rent" />
            <div className={setting_css.setting_wrap}>
              <div className="section">
                <h4 className="section_label">Rent Settings</h4>
                <div className="section_body">
                  <form>
                    <div className={setting_css.setting_item}>
                      <label>Lender approve timeout</label>
                      <input
                        style={{ borderColor: this.state.approveError ? "red" : 'transparent' }}
                        className="form-control"
                        type="text"
                        placeholder="ex: 25 (value count as minite)"
                        value={this.state.approve_timeout}
                        onChange={e => this.setState({ approve_timeout: e.target.value })}
                      />
                    </div>
                    {/* checkout timeout */}
                    <div className={setting_css.setting_item}>
                      <label>Rental checkout timeout</label>
                      <input
                        style={{ borderColor: this.state.requestError ? "red" : 'transparent' }}
                        className="form-control"
                        type="text"
                        placeholder="ex: 26 (value count as minite)"
                        value={this.state.request_timeout}
                        onChange={e => this.setState({ request_timeout: e.target.value })}
                      />
                    </div>
                    {/* shipping timeout */}
                    <div className={setting_css.setting_item}>
                      <label>Lender shipping timeout</label>
                      <input
                        style={{ borderColor: this.state.shippingError ? "red" : 'transparent' }}
                        className="form-control"
                        type="text"
                        placeholder="ex: 27 (value count as minite)"
                        value={this.state.shipping_timeout}
                        onChange={e => this.setState({ shipping_timeout: e.target.value })}
                      />
                    </div>
                    {/* return timeout */}
                    <div className={setting_css.setting_item}>
                      <label>Return timeout</label>
                      <input
                        style={{ borderColor: this.state.returnError ? "red" : 'transparent' }}
                        className="form-control"
                        type="text"
                        placeholder="ex: 28 (value count as minite)"
                        value={this.state.return_timeout}
                        onChange={e => this.setState({ return_timeout: e.target.value })}
                      />
                    </div>
                    {/* service charge */}
                    <div className={setting_css.setting_item}>
                      <label>Service Charge</label>
                      <input
                        style={{ borderColor: this.state.chargeError ? "red" : 'transparent' }}
                        className="form-control"
                        type="text"
                        placeholder="ex: 9 (value count as 9%)"
                        value={this.state.charge}
                        onChange={e => this.setState({ charge: e.target.value })}
                      />
                    </div>
                    {/* Minimum Withdraw */}
                    <div className={setting_css.setting_item}>
                      <label>Minimum Withdraw</label>
                      <input
                        style={{ borderColor: this.state.minimumError ? "red" : 'transparent' }}
                        className="form-control"
                        type="text"
                        placeholder="ex: 550 (value count as €550)"
                        value={this.state.minimum_withdraw}
                        onChange={e => this.setState({ minimum_withdraw: e.target.value })}
                      />
                    </div>
                    <div
                      onClick={() => save()}
                      className={`btn btn-sm btn-success ${setting_css.save_setting_btn}`}
                    >
                      save setting
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rent);
