import * as actionTypes from "./actionTypes";
import { INITIAL_STATE } from "./Initialstate";

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        accessToken: action.data.access_key,
        loading: false,
      };

    case actionTypes.AUTH_LOADING:
      return {
        ...state,
        loading: true,
        accessToken: null,
      };
    case actionTypes.AUTH_FAILED:
      return {
        ...state,
        loading: false,
        accessToken: null,
      };
    case action.LOGOUT_SUCCESS:
      return {
        ...state,
        accessToken: null,
        version: "ninthweb1.0",
        loading: false,
      };
    default:
      return state;
  }
};

export default Reducer;
