import React from "react";

const Slider = ({ data = [] }) => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide carousel_change_wraper"
      data-bs-ride="true"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        {data.slice(1, 10).map((_, index) => {
          return (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={`${index + 1}`}
              aria-label={`Slide ${index + 1}`}
            ></button>
          );
        })}
      </div>
      <div className="carousel-inner">
        {data.length > 0 ? (
          <>
            <div className="carousel-item active">
              <img
                style={{
                  objectFit: "contain",
                }}
                src={data[0]}
                className="d-block w-100"
                alt="..."
              />
            </div>
            {data.slice(1, 10).map((item, index) => {
              return (
                <div key={index} className="carousel-item">
                  <img src={item} className="d-block w-100" alt="..." />
                </div>
              );
            })}
          </>
        ) : null}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Slider;
