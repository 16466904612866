import dateFormat from "dateformat";
const dateValidator = (date) => {
  var isValidDate = false;
  try {
    let dt = dateFormat(new Date(date), "dd/mm/yyyy HH:MM");
    try {
      var arr1 = dt.split("/");
      var year = 0;
      var month = 0;
      var day = 0;
      var hour = 0;
      var minute = 0;
      var sec = 0;
      if (arr1.length === 3) {
        var arr2 = arr1[2].split(" ");
        if (arr2.length === 2) {
          var arr3 = arr2[1].split(":");
          try {
            year = parseInt(arr2[0], 10);
            month = parseInt(arr1[1], 10);
            day = parseInt(arr1[0], 10);
            hour = parseInt(arr3[0], 10);
            minute = parseInt(arr3[1], 10);
            //sec = parseInt(arr3[0],10);
            sec = 0;
            var isValidTime = false;
            if (
              hour >= 0 &&
              hour <= 23 &&
              minute >= 0 &&
              minute <= 59 &&
              sec >= 0 &&
              sec <= 59
            )
              isValidTime = true;
            else if (hour === 24 && minute === 0 && sec === 0)
              isValidTime = true;

            if (isValidTime) {
              var isLeapYear = false;
              if (year % 4 === 0) isLeapYear = true;

              if (
                (month === 4 || month === 6 || month === 9 || month === 11) &&
                day >= 0 &&
                day <= 30
              )
                isValidDate = true;
              else if (month !== 2 && day >= 0 && day <= 31) isValidDate = true;

              if (!isValidDate) {
                if (isLeapYear) {
                  if (month === 2 && day >= 0 && day <= 29) isValidDate = true;
                } else {
                  if (month === 2 && day >= 0 && day <= 28) isValidDate = true;
                }
              }
            }
          } catch (er) {
            isValidDate = false;
          }
        }
      }
    } catch (err) {
      isValidDate = false;
    }
  } catch {
    isValidDate = false;
  }
  return isValidDate;
};

export default dateValidator;
