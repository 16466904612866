import React from "react";

const Error = () => {
  return (
    <div className="error-page">
      <h2>404 Not Found</h2>
      <h4>or unauthorize</h4>
      <a
        style={{
          fontSize: 20,
        }}
        href="/"
      >
        back to home
      </a>
    </div>
  );
};

export default Error;
