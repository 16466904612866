import React, { Component } from "react";
import "../css/wrap.css";
export class Contentwrap extends Component {
  render() {
    return (
      <div className="content_wrap">
        {this.props.children ? this.props.children : null}
      </div>
    );
  }
}

export default Contentwrap;
