import React, { Component } from "react";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import rental_css from "../../css/rentals.module.css";
import { connect } from "react-redux";
import Selfspinner from "../../component/Selfspinner";
import Spinner from "../../component/Spinner";
import dateFormat from "dateformat";
// dispatch actions
import { Rentalsget } from "../../redux/actionCreator/Coreactions";
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Rentalsget_dis: (token, ld, setState, is_loadnore, state) =>
      dispatch(Rentalsget(token, ld, setState, is_loadnore, state)),
  };
};

export class Rentals extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      loadingld: false,
      rentals: [],
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.props.Rentalsget_dis(
      this.props.accessToken,
      0,
      this.setState,
      false,
      this.state
    );
  }
  render() {
    const load_more = () => {
      this.props.Rentalsget_dis(
        this.props.accessToken,
        this.state.rentals.length,
        this.setState,
        true,
        this.state
      );
    };
    const export_exal = async () => {
      const export_json = [];
      for (let renitem of this.state.rentals) {
        const jsonobj = {
          "List Title": renitem.list_title,
          "List Category": renitem.list_category,
          "Lender Username": renitem.list_len_username,
          "Renter Username": renitem.list_ren_username,
          "Rental Start Date": renitem.start,
          "Rental End Date": renitem.finish,
          "Rental Price": renitem.price,
          "Discount Code Used Discount": renitem.discount_used,
          "Discount Code %": renitem.discount_persent,
          "Pick-Up or Delivery": renitem.pickup_or_delivery,
          "Total Cost of Rental": renitem.total_cost_ren,
          "Rental Request Sent Date & Time": renitem.checkout_time,
          "Rental Request Response": renitem.status,
          "Rental Request Response Date & Time": renitem.lender_response_time,
          "Lender Review of Item": renitem.rental_review,
          "Renter Review of Item": renitem.lender_review,
          "Renter Photo included in Review": renitem.rental_review_photo_inc,
        };
        export_json.push(jsonobj);
      }
      const file_type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(export_json);
      for (let i = 1; i <= ws.columnCount; i++) {
        const column = ws.getColumn(i);
        column.width = 200;
      }
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const exel_buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([exel_buffer], { type: file_type });
      FileSaver.saveAs(
        data,
        `ninthlife_rentals_export${dateFormat(
          new Date(),
          "dd-mm-yyyy-HH:MM-TT"
        )}.xlsx`
      );
    };
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Lists" />
          <Contentwrap>
            <Header path="Rentals" subpath="home/rentals" />
            <div style={{ position: "relative" }} className="section">
              <button
                className={`${rental_css.export_exal_btn} btn btn-success`}
                onClick={() => export_exal()}
              >
                export excel
              </button>
              <h4 className="section_label">Rentals</h4>
              <div className={rental_css.section_body}>
                <div
                  className={`table-responsive ${rental_css.rental_table_wrap}`}
                >
                  <table className="table table-hover table_change">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" className="min_width200">
                          List Title
                        </th>
                        <th scope="col" className="min_width200">
                          List Category
                        </th>
                        <th scope="col" className="min_width200">
                          Lender Username
                        </th>
                        <th scope="col" className="min_width200">
                          Renter Username
                        </th>
                        <th scope="col" className="min_width200">
                          Rental Period
                        </th>
                        <th scope="col" className="min_width200">
                          Rental Start Date
                        </th>
                        <th scope="col" className="min_width200">
                          Rental End Date
                        </th>
                        <th scope="col" className="min_width200">
                          Rental Price
                        </th>
                        <th scope="col" className="min_width200">
                          Discount Code Used
                        </th>
                        <th scope="col" className="min_width200">
                          Discount Code %
                        </th>
                        <th scope="col" className="min_width200">
                          Pick-Up or Delivery
                        </th>
                        <th scope="col" className="min_width200">
                          Total Cost of Rental
                        </th>
                        <th scope="col" className="min_width200">
                          Rental Request Sent Date & Time
                        </th>
                        <th scope="col" className="min_width200">
                          Rental Request Response
                        </th>
                        <th scope="col" className="min_width200">
                          Rental Request Response Date & Time
                        </th>
                        <th scope="col" className="min_width200">
                          Lender Review of Item
                        </th>
                        <th scope="col" className="min_width200">
                          Renter Review of Item
                        </th>
                        <th scope="col" className="min_width200">
                          Renter Photo included in Review
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rentals.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.list_title}</td>
                            <td>{item.list_category}</td>
                            <td>{item.list_len_username}</td>
                            <td>{item.list_ren_username}</td>
                            <td>{item.duration}</td>
                            <td>{item.start}</td>
                            <td>{item.finish}</td>
                            <td>{item.price}</td>
                            <td>{item.discount_used}</td>
                            <td>{item.discount_persent}</td>
                            <td>{item.pickup_or_delivery}</td>
                            <td>{item.total_cost_ren}</td>
                            <td>{item.checkout_time}</td>
                            <td>{item.status}</td>
                            <td>{item.lender_response_time}</td>
                            <td>{item.discount_used}</td>
                            <td>{item.rental_review}</td>
                            <td>{item.lender_review}</td>
                            <td>{item.rental_review_photo_inc}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {this.state.loadingld ? (
                  <div className="load_more_spinner_wrap">
                    <Selfspinner />
                  </div>
                ) : (
                  <button
                    onClick={() => load_more()}
                    className={rental_css.load_more}
                  >
                    Load more
                  </button>
                )}
              </div>
            </div>
          </Contentwrap>
          {this.state.loading ? <Spinner /> : null}
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rentals);
