import React, { Component } from "react";
import Wrap from "../../component/Wrap";
import Flexwrap from "../../component/Flexwrap";
import Contentwrap from "../../component/Contentwrap";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import "../../css/notification.css";
import { connect } from "react-redux";
import Selfspinner from "../../component/Selfspinner";
// dispatch actions
import { Allusersnotification } from "../../redux/actionCreator/Coreactions";

const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Allusersnotification_dis: (token, data, setState) =>
      dispatch(Allusersnotification(token, data, setState)),
  };
};

export class Notification extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      picture: null,
      message: "",
      loadingnt: false,
    };
    this.setState = this.setState.bind(this);
  }
  render() {
    const update_state = (e, key) => {
      this.setState({
        [key]: key === "picture" ? e.target.files[0] : e.target.value,
      });
    };
    const sent_notification = (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("title", this.state.title);
      formData.append("picture", this.state.picture);
      formData.append("message", this.state.message);
      this.props.Allusersnotification_dis(
        this.props.accessToken,
        formData,
        this.setState
      );
    };
    return (
      <Wrap>
        <Flexwrap>
          <Sidebar active="Home" />
          <Contentwrap>
            <Header path="Home" subpath="home/dashboard" />
            <div className="section">
              <h3 className="section_label">Create Notification</h3>
              <div className="section_body">
                <div className="notification_wrap">
                  <form onSubmit={sent_notification}>
                    <label>Notification Title</label>
                    <input
                      className="form-control"
                      value={this.state.title}
                      onChange={(e) => update_state(e, "title")}
                      type="text"
                      placeholder="e.g [Notice] "
                      required
                    />
                    {/* picture */}
                    <label>Notification Picture</label>
                    <input
                      className="form-control"
                      type="file"
                      onChange={(e) => update_state(e, "picture")}
                      placeholder="e.g [Notice] "
                      accept="image/png, image/gif, image/jpeg"
                    />
                    {/* message */}
                    <label>Notification Message</label>
                    <input
                      className="form-control"
                      value={this.state.message}
                      onChange={(e) => update_state(e, "message")}
                      type="text"
                      placeholder="e.g Thanks for using Ninth Life "
                      required
                    />
                    <button
                      style={{ minWidth: 100 }}
                      className="btn btn-sm btn-success mt-2 mb-3"
                    >
                      {this.state.loadingnt ? (
                        <Selfspinner size={26} />
                      ) : (
                        "Notify to All users"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Contentwrap>
        </Flexwrap>
      </Wrap>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
