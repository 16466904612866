import React from 'react'
import sfspinner_css from "../css/sfspinner.module.css";
const Selfspinner = ({ size = 30 }) => {
  return (
    <div className={sfspinner_css.sfspinner}>
      <div style={{
        width: size,
        height: size
      }} className={sfspinner_css.spin_wrap}>
        <div className={`spinner-border text-info ${sfspinner_css.sfspinner_ld}`} role="status">
          <span className="visually-hidden"></span>
        </div>
      </div>
    </div>
  )
}

export default Selfspinner;



